import axios from "axios";

const baseURL = `${process.env.VUE_APP_API_URL}/api/Suppliers`;

export default {
  async getCurrentSupplier() {
    const response = await axios.get(`${baseURL}/current`);
    return response.data.result;
  },
};
