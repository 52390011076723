<template>
  <div id="login-page">
    <div class="login-body">
      <div class="login-tile">
        <div class="login-tile-header">
          <img alt="FixITOnline logo" height="25" src="../assets/FixIT_Online_logo_hvit.svg"/>
        </div>

        <div class="login-tile-body">
          <v-text-field v-model="username" label="Brukernavn" prepend-icon="mdi-account" @keydown.enter="login"/>
          <v-text-field type="password" v-model="password" label="Passord" prepend-icon="mdi-lock"
                        @keydown.enter="login"/>

          <div class="mt-4">
            <v-checkbox label="Logg inn automatisk"/>
          </div>

          <v-btn :loading="loggingIn" class="my-4" color="error" @click="login">LOGG INN</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data: () => {
    return {
      loggingIn: false,
      username: null,
      password: null
    }
  },
  methods: {
    async login() {
      this.loggingIn = true;
      let response = await axios.post(`${process.env.VUE_APP_FIXIT_CORE_API_URL}/api/v2/authorization/Login`, {
        username: this.username,
        password: this.password
      }, {withCredentials: true})
      if (response.status === 200) {
        localStorage.setItem("JWTtoken", response.data.jwtToken)
        window.location.href = this.$route.query?.ReturnUrl || '/'
      }
      this.loggingIn = false;
    }
  }
}
</script>

<style lang="scss" scoped>

#login-page {
  height: 100vh;
  width: 100vw;
  background-color: #e3e5e6;
}

.login-body {
  max-width: 28rem;
  padding-top: 7rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: .5rem;
  padding-left: .5rem;
}

.login-tile {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12);
  border-radius: 2px;
  background-color: #fff;
}

.login-tile .login-tile-header {
  background-color: #454749;
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
  margin-bottom: 0;
}

.login-tile .login-tile-body {
  padding: 1.25rem;
}

</style>
