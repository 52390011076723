import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
//import modules
import authorization from "./modules/data/views/authorization";
import basicImport from "./modules/data/views/basic-import";
import brands from "./modules/data/views/brands";
import series from "./modules/data/views/series";
import products from "./modules/data/views/products";
import categories from "./modules/data/views/categories";
import notifications from "./modules/notifications";
import suppliers from "./modules/data/views/suppliers";
import productForm from "./modules/data/views/product-form";

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authorization,
    "basic-import": basicImport,
    brands,
    series,
    products,
    categories,
    notifications,
    suppliers,
    "product-form": productForm,
  },
});
