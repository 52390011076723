import axios from "axios";
import ServiceException from "@/models/ServiceException";

const baseURL = `${process.env.VUE_APP_FIXIT_CORE_API_URL}/api/Authorization`;

export default {
  async getCurrentOdinUser() {
    const response = await axios.get(`${baseURL}/GetCurrentOdinUser`);
    if (response?.data?.responseCode !== 200) {
      throw new ServiceException(response?.data);
    }
    return response.data.dto;
  },
};
