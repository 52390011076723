<template>
  <v-container class="apiDocumentationApp">
    <documentation-drawer-menu :items="apiContent"></documentation-drawer-menu>
    <v-row class="apiDocumentationSectionsWrapper" justify="center">
      <v-col md="6">
        <documentation-content-section :items="apiContent"></documentation-content-section>
      </v-col>
    </v-row>  
  </v-container>
</template>
<script>
import DocumentationDrawerMenu from "./components/DocumentationDrawerMenu.vue";
import DocumentationContentSection from "./components/DocumentationContentSection.vue";
import umbracoContent from "@/services/umbraco-content.js";
export default {
  name: "ApiDocumentation",
  components: {
    DocumentationDrawerMenu,
    DocumentationContentSection
  },
  data() {
    return {
      apiContent: [],
      throttleTimer: false
    }
  },
  computed:{
    sectionElementsArray(){
      return document.querySelectorAll('.apiContentSectionCard')
    }
  },
  methods: {
    throttleScroll(callback){
      if (this.throttleTimer) return;
      this.throttleTimer = true;
      setTimeout(() => {
        callback();
        this.throttleTimer = false;
      }, 200)
    },
    setActiveMenuItem(){
      for(var i = 0; i < this.sectionElementsArray.length; i++){
          if(this.sectionElementsArray[i].children[2].getBoundingClientRect().top > eval(document.querySelector('.v-toolbar__content').getBoundingClientRect().top) + 118){
            let el = document.querySelector('.drawerMenuItem[data-relatedsectionid="'+this.sectionElementsArray[i].id+'"]')
            el.classList.add('activeMenuItem')
            document.querySelectorAll('.drawerMenuItem:not([data-relatedsectionid="'+this.sectionElementsArray[i].id+'"])').forEach(function(elem){
              elem.classList.remove('activeMenuItem')
            })
            break;
          }
        }
    }
  },
  async mounted(){
    this.apiContent = (await umbracoContent.getContent()).data.children;
    document.addEventListener('scroll', this.setActiveMenuItem)
  },
  destroyed(){
    document.removeEventListener('scroll', this.setActiveMenuItem)
  }
}

</script>
<style lang="scss" scoped>
.apiDocumentationApp{
  background-color: rgb(71 36 73 / 10%);
  
}
</style> 
<style lang="scss">
.apiDocumentationApp{
  .apiContentSectionCard{
    pre{
      background-color: #f5f5f5;
      margin-top: 10px;
    }
    &.subItem{
      margin-top: 0 !important;
      >h4{
        font-family: 'Niveau Grotesk';
        font-weight: 500;
        font-size: 26px;
        color: #4a4a4a;
        border-bottom: 0;
      }
    }
    >h4{
        font-family: 'Niveau Grotesk';
        font-weight: 700;
        font-size: 30px;
        border-bottom: 1px solid #4a4a4a;
        float: left;
        clear: both;
        color: #323232;
      }
    .apiContentSectionCardContent{ 
      width: 100%;
      clear: both;
      >p{
        font-size: 16px;
        font-family: 'Niveau Grotesk';
        color: #4a4a4a;
      }
      img{
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
      }
      ol, ul{
        font-size: 16px;
        color: #4a4a4a;
      
      }
    }
  }
}
</style>