import Vue from 'vue'
import VueWait from "vue-wait";

const wait = new VueWait({
    useVuex: true,
});

Vue.use(VueWait);

export default wait
