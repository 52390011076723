import keyBy from "lodash.keyby";
import orderBy from "lodash.orderby";
import mutations from "@/store/mutations";
import BrandsService from "@/services/brands";
import trim from "lodash.trim";
import imageSvc from "@/services/images";
import { SupplierBrand } from "@/models/enums/ImageTypes";

export default {
  namespaced: true,
  state: {
    loadingBrands: false,
    allFetched: false, // keep track if all brands for  supplier have been fetched
    brands: {}, // brands stored in a object with brand.id as key
  },
  getters: {
    getBrands(state) {
      const brands = state.allFetched ? Object.values(state.brands) : [];
      return orderBy(brands, "name");
    },
    getBrandById: (state) => (id) => {
      return state.brands[id];
    },
    brandNameExists: (state, getters) => (name) => {
      const brands = getters.getBrands;
      return brands.some(
        (brand) => trim(brand.name).toLowerCase() == trim(name).toLowerCase()
      );
    },
  },
  actions: {
    async fetchBrands({ commit, state }, supplierId) {
      if (!state.loadingBrands) {
        commit("set", { key: "loadingBrands", value: true });
        const brands = await BrandsService.getBrands(supplierId);
        commit("set", { key: "loadingBrands", value: false });
        commit("set", { key: "allFetched", value: true });
        commit("addOrUpdate", brands);
      }
    },
    async fetchBrand({ commit }, id) {
      const brand = await BrandsService.getBrand(id);
      commit("addOrUpdate", brand);
    },
    async updateBrand({ commit }, brand) {
      const updatedBrand = await BrandsService.updateBrand(brand);
      if (updatedBrand) {
        commit("addOrUpdate", updatedBrand);
      }
      return updatedBrand;
    },
    async createBrand({ commit }, { brand, image }) {
      if (!brand) return null;
      let createdBrand = await BrandsService.createBrand(brand);
      if (createdBrand && image) {
        await imageSvc.uploadBase64Image({
          imageId: createdBrand.id,
          imageType: SupplierBrand,
          imageBase64: image,
        });
      }
      if (createdBrand) {
        commit("addOrUpdate", createdBrand);
      }
      return createdBrand;
    },
  },
  mutations: {
    ...mutations,
    addOrUpdate(state, brands) {
      state.brands = { ...state.brands, ...keyBy([].concat(brands), "id") };
    },
  },
};
