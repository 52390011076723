import keyBy from "lodash.keyby";
import orderBy from "lodash.orderby";
import mutations from "@/store/mutations";
import CategoriesService from "@/services/categories";

export default {
  namespaced: true,
  state: {
    hasFetchedCategories: false,
    categories: {}, // categories stored in a object with category.id as key
  },
  getters: {
    getCategories(state) {
      return orderBy(Object.values(state.categories), "name");
    },
    getCategoryById: (state) => (id) => {
      return state.categories[id];
    },
  },
  actions: {
    async fetchCategories({ commit }) {
      const categories = await CategoriesService.getCategories();
      commit("set", { key: "hasFetchedCategories", value: true });
      commit("addOrUpdate", categories);
    },
  },
  mutations: {
    ...mutations,
    addOrUpdate(state, categories) {
      state.categories = {
        ...state.categories,
        ...keyBy([].concat(categories), "id"),
      };
    },
  },
};
