import ProductDataError from "./ProductDataError";

export default class ProductData {
    constructor(props) {
        this.id = props.id
        this.importId = props.importId
        this.productId = props.productId
        this.supplementaryNumber = props.supplementaryNumber
        this.productShortName = props.productShortName
        this.productLongName = props.productLongName
        this.brandId = props.brandId
        this.brandName = props.brandName
        this.serieId = props.serieId
        this.serieName = props.serieName
        this.categoryName = props.categoryName
        this.vat = props.vat
        this.productType = props.productType
        this.inPriceExVat = props.inPriceExVat
        this.recommendedSalesPriceIncVat = props.recommendedSalesPriceIncVat
        this.ean = props.ean
        this.articleType = props.articleType
        this.shelfDescription = props.shelfDescription
        this.size = props.size
        this.unit = props.unit
        this.description = props.description
        this.packageSize = props.packageSize
        this.minimumOrderQuantity = props.minimumOrderQuantity
        this.orderAvailableFrom = props.orderAvailableFrom
        this.orderAvailableTo = props.orderAvailableTo
        this.imagesFileNames = props.imagesFileNames
        this.activeStatus = props.activeStatus
        this.isImportable = props.isImportable
        this.isImported = props.isImported
        this.productExistsInOpp = props.productExistsInOpp
        this.rowRawData = props.rowRawData
        this.errorList = (props.errorList || []).map(x => new ProductDataError(x))
        this.importError = props.importError
    }

}
