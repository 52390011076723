export default class ColumnMapping {
    constructor(args) {
        this.id = args?.id
        this.fileColumnIndex = args?.fileColumnIndex
        this.fixitColumnId = args?.fixitColumnId
        this.lastUpdatedByUserId = args?.lastUpdatedByUserId
        this.lastUpdatedTime = args?.lastUpdatedTime
        this.importId = args?.importId
    }
}
