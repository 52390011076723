import store from "@/store";

// Variants/colors from vuetify standard theme
// Use Object {name: 'custom', color: "red"} for custom variants
const variants = [
  "primary",
  "secondary",
  "accent",
  "error",
  "info",
  "success",
  "warning",
  { name: "snackbar", color: "rgba(0,0,0,0.7)", x: "bottom", y: "center" },
];

function Notifier() {
  let self = this;
  // Create Notifier methods for each variant
  variants.forEach(function (variant) {
    let color = "";
    let name = "";
    let x = "right";
    let y = "top";
    if (typeof variant === "object") {
      name = variant.name;
      color = variant.color;
      x = variant.x || x;
      y = variant.y || y;
    } else {
      name = variant;
      color = variant;
    }
    // See https://vuetifyjs.com/en/components/snackbars for all options
    self[name] = function (message, options) {
      var args = Object.assign(
        {
          text: message,
          color,
          x,
          y,
        },
        Object(options)
      );
      store.commit("notifications/set", args); // This sets the snackbar in Vuex store - Which trigges the TheSnackbar component to show.
    };
  });
}

if (process.env.NODE_ENV !== "production") {
  window.notifier = new Notifier();
}

export default new Notifier();
