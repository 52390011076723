import mutations from "@/store/mutations";
import AuthorizationService from "@/services/authorization";

export default {
  namespaced: true,
  state: {
    hasFetchedCurrentOdinUser: false,
    currentOdinUser: null,
    userRole: ''
  },
  actions: {
    async fetchCurrentOdinUser({ commit }) {
      const currentOdinUser = await AuthorizationService.getCurrentOdinUser();
      commit("set", { key: "hasFetchedCurrentOdinUser", value: true });
      commit("set", { key: "currentOdinUser", value: currentOdinUser });
      if(currentOdinUser.userRoles.find(u => u.roleName === 'Supplier')){
        commit("set", { key: "userRole", value: 'Supplier' })
      } else { 
        commit("set", { key: "userRole", value: 'agent' }) 
      }
    },
  },
  mutations: {
    ...mutations,
  }
};
