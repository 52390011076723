import Vue from "vue";
import VueCurrencyFilter from "vue-currency-filter";

const options = {
  symbol: "kr",
  thousandsSeparator: " ",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
};
Vue.use(VueCurrencyFilter, options);
