<template>
  <v-container>
    <progress-bouncing
      center
      class="mt-11"
      v-if="$wait.is('loading basic-import get-imports')"
    />
    <div class="basic-import" v-else>
      <v-dialog
        v-model="showErrorLines"
        fullscreen
        hide-overlay
        transition="none"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Errors</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="showErrorLines = false">
                <v-icon>fxt-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-simple-table dense class="py-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">ProductId</th>
                    <th class="text-left">Error message</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in expandedErrorLines" :key="item.Id">
                    <td>{{ item.ProductId }}</td>
                    <td>
                      {{
                        item.ImportError.substr(
                          item.ImportError.indexOf("message:") + 8
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <h1 class="text-left">Import</h1>
      <div class="text-left">
        <v-btn outlined>Filter</v-btn>
      </div>
      <div class="d-flex justify-space-between my-3">
        <div class="align-self-end">{{ importList.length }} results</div>
        <v-btn color="primary" :to="{ name: 'BasicImport.CreateNew' }"
          >Import data</v-btn
        >
      </div>
      <v-data-table :items="importList" :headers="headers">
        <template v-slot:[`item.createdTime`]="{ item }">
          {{ moment(item.createdTime).format("DD.MM.YYYY HH:mm") }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div v-if="item.status !== 1">
            {{ importStatus(item.status) }}
          </div>
          <div v-else>
            <v-btn text icon loading></v-btn>
          </div>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-btn
            v-if="item.total !== item.imported"
            text
            @click="expandItem(item.errorLines)"
            ><v-icon>fxt-warning</v-icon></v-btn
          >
        </template>
      </v-data-table>
      <basic-import-wizard />
    </div>
  </v-container>
</template>

<script>
import BasicImportWizard from "./views/wizard/BasicImportWizard";
import { waitFor } from "vue-wait/src/helpers";

export default {
  name: "BasicImport",
  components: { BasicImportWizard },
  data() {
    return {
      importList: [],
      refreshImportsInterval: null,
      headers: [
        { text: "Date", value: "createdTime", name: "createdTime" },
        { text: "Created by", value: "createdByUserFullName" },
        { text: "Status", value: "status" },
        { text: "Total", value: "total", sortable: false, width: 70 },
        { text: "Imported", value: "imported", sortable: false, width: 70 },
        { text: "", value: "details", sortable: false, width: 70 },
      ],
      expandedErrorLines: [],
      showErrorLines: false,
    };
  },
  computed: {
    importStatus() {
      return (item) => {
        switch (item) {
          case 0:
            return "Created";
          case 1:
            return "In progress";
          case 2:
            return "Imported";
          case 3:
            return "Failed";
          case 4:
            return "Canceled";
        }
      };
    },
  },
  methods: {
    expandItem(item) {
      this.expandedErrorLines = item.filter(
        (l) => l.ProductId && l.ImportError
      );
      this.showErrorLines = true;
    },
    async getImports() {
      waitFor("loading basic-import get-imports", async () => {
        this.importList = await this.$store.dispatch("basic-import/getImports");
      }).apply(this);
    },
  },
  created() {
    this.getImports();
    waitFor("loading basic-import get-fixit-columns", async () => {
      await this.$store.dispatch("basic-import/getFixitColumns");
    }).apply(this);
    this.refreshImportsInterval = setInterval(
      async () =>
        (this.importList = await this.$store.dispatch(
          "basic-import/getImports"
        )),
      5000
    );
  },
  destroyed() {
    clearInterval(this.refreshImportsInterval);
  },
};
</script>
<style lang="scss" scoped>
.basic-import {
  color: var(--v-primary-base) !important;
}

.v-data-table {
  color: var(--v-primary-base) !important;

  ::v-deep th {
    color: inherit !important;
    background-color: #efddd1;
    z-index: 1;
  }
}
</style>
