// Helper function for determing if user has access - TODO: Ensure these checks also exist in the backend services
export const hasAccess = function (route, userRoles) {
  var roles = route.meta.roles || []
  let localRoles = userRoles.map(function (role) { return role.roleName })
  let hasAccess = roles.length === 0 || roles.some(role => localRoles.includes(role))
  return hasAccess
}

export default {
  hasAccess
}
