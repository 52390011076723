import axios from "axios";
import ServiceException from "@/models/ServiceException";

const imageUploadUrl = `${process.env.VUE_APP_FIXIT_CORE_API_URL}/api/content/upload-image`;

async function toBlob(base64str) {
  if (!base64str) return null;
  const result = await fetch(base64str);
  return await result.blob();
}

function validate(imageData) {
  const requiredProps = ["imageId", "imageType", "imageBase64"];
  requiredProps.forEach((prop) => {
    if (!imageData[prop]) {
      throw `Missing required property: "${prop}"`;
    }
  });
}

async function createFormData(imageData) {
  const imageFile = await toBlob(imageData.imageBase64);
  let formData = new FormData();
  formData.append("File", imageFile);
  formData.append("image.imageType", imageData.imageType);
  formData.append("image.imageId", imageData.imageId);
  return formData;
}

export default {
  async uploadBase64Image(imageData) {
    validate(imageData);
    const formData = await createFormData(imageData);
    const response = await axios.post(imageUploadUrl, formData);
    if (response?.data?.responseCode !== 200) {
      throw new ServiceException(response?.data);
    }
    return response.data.dto;
  },
};
