import getters from '../../../getters';
import actions from "@/store/actions";
import mutations from "@/store/mutations";

import BasicImportApi from '@/services/basic-import-api';
import cloneDeep from 'lodash.clonedeep';
import FixitColumn from "@/models/FixitColumn";
import BasicImport from "@/models/BasicImport";
import ProductDataError from "@/models/ProductDataError";
import ProductData from "../../../../models/ProductData";

export default {
    namespaced: true,
    state: {
        basicImport: null,
        fixitColumns: []
    },
    modules: {},
    getters: {
        ...getters
    },
    actions: {
        ...actions,
        async createNewImport({commit}, payload) {
            const response = await BasicImportApi.createNewImport(payload);
            return response.data.result
        },
        async updateImport({commit}, payload) {
            const response = await BasicImportApi.updateImport(payload);
            return response.data?.responseCode === 200
        },
        async deleteImport({commit}, payload) {
            const response = await BasicImportApi.deleteImport(payload);
            return response.data?.responseCode === 200
        },
        async uploadImportFile({commit}, payload) {
            const response = await BasicImportApi.uploadImportFile(payload);
            return response.data.responseCode === 200
        },
        async getActiveImport({commit}) {
            const response = await BasicImportApi.getActiveImport();
            if (response.data.responseCode === 200)
                commit('set', {
                    key: 'basicImport',
                    value: new BasicImport(response.data.result)
                })
        },
        async parseAndValidateImport({state, commit}, payload) {
            const response = await BasicImportApi.parseAndValidateImport({importId: state.basicImport.id});
            return (response.data.result || []).map(x => new ProductData(x))
        },
        async getImportPreview({state, dispatch}, payload) {
            await dispatch('parseAndValidateImport')
            const response = await BasicImportApi.getImportPreview({importId: state.basicImport.id});
            return response.data.result
        },
        async getFixitColumns({commit}) {
            const response = await BasicImportApi.getFixitColumns();
            response.data.responseCode === 200 ? commit('set', {
                key: 'fixitColumns',
                value: response.data.result.map(x => new FixitColumn(x))
            }) : [];
        },
        async mapColumns({commit, dispatch}, payload) {
            const response = await BasicImportApi.mapColumns(payload);
            return response.data.result
        },
        async runImport({commit}, payload) {
            const response = await BasicImportApi.executeImport(payload);
            return response.data.result
        },
        async getImports({commit}) {
            const response = await BasicImportApi.getImports();
            response.data.result.forEach((line) => line.errorLines = JSON.parse(line.errorLines));
            return response.data.result || []
        }
    },
    mutations: {
        ...mutations,
    }
}
