<template>
  <v-snackbar
    v-if="snackbarVisibility"
    style="z-index: 9999; pointer-events: none"
    v-model="snackbarVisibility"
    :absolute="snackbar.absolute"
    :color="snackbar.color"
    :multi-line="snackbar.multiLine"
    :timeout="snackbar.timeout"
    :vertical="snackbar.vertical"
    :top="snackbar.y === 'top'"
    :right="snackbar.x === 'right'"
    :bottom="snackbar.y === 'bottom'"
    :left="snackbar.x === 'left'"
  >
    <span class="d-flex justify-space-between align-center">
      <span v-html="snackbar.text"></span>

      <v-btn
        dark
        text
        icon
        @click.native="snackbarVisibility = false"
        v-if="snackbar.showCloseButton"
        small
      >
        <v-icon>close</v-icon>
      </v-btn>
    </span>
  </v-snackbar>
</template>

<script>
export default {
  name: "TheSnackbar",
  computed: {
    snackbar() {
      return this.$store.getters["notifications/get"];
    },
    snackbarVisibility: {
      get() {
        return this.snackbar.snackbar;
      },

      set(snackbar) {
        if (!snackbar) this.$store.commit("notifications/reset");
      },
    },
  },
};
</script>
