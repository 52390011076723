import ProductForm from "@/models/ProductForm";
import Product from "@/models/Product";
import Vue from "vue";
import notification from "@/utils/notification";
import imageSvc from "@/services/images";
import productSvc from "@/services/products";
import { SupplierProduct } from "@/models/enums/ImageTypes";
import moment from "moment";

const getProductFromForm = (context) => {
  const form = context.state.productForm;
  const brand = context.rootGetters["brands/getBrandById"](form.brandId);
  const serie = context.rootGetters["series/getSerieById"](
    form.productSeriesId
  );
  const category = context.rootGetters["categories/getCategoryById"](
    form.productCategoryId
  );

  let currentProduct =
    context.rootGetters["products/getProductById"](form.id) || {};

  const product = new Product({
    ...currentProduct, // Fill with current product values
    ...form, // Update with values from form
    articleType: form.isSalesProduct ? "S" : "F",
    dataProviderBrandId: brand?.dataProviderBrandId,
    dataProviderProductSerieId: serie?.dataProviderProductSerieId,
    dataProviderCategoryId: category?.dataProviderCategoryId,
    productCategoryName: category?.name,
    isPackage: form.packageSize > 1,
    ean: form.id === 0 ? form.ean : "", // when creating the form value is used, else ean is added with plus-button
  });
  return product;
};

// Converts a date to null if the date is less or equal to a given minDate (only checking fullYear)
function toNullableMoment({ dt, minDate = 1900 }) {
  if (dt == null) return null;
  let m = moment(dt);
  if (m.year() <= minDate) return null;
  return m;
}

function setCampaignDates({ product, productForm, emptyForm }) {
  productForm.campaignFromDate =
    product?.campaignFromDate != null
      ? product?.campaignFromDate
      : emptyForm.campaignFromDate;

  productForm.campaignToDate =
    product?.campaignToDate != null
      ? product?.campaignToDate
      : emptyForm.campaignToDate;
}

export default {
  namespaced: true,
  state: {
    productForm: new ProductForm(),
    validationObserver: null,
    isSaving: false,
    // Errors messages from server
    serverErrors: {
      dataProviderProductId: [],
      supplementaryNumber: [],
    },
    serverValidationPending: {
      dataProviderProductId: false,
      supplementaryNumber: false,
    },
  },
  getters: {
    hasServerErrors(state) {
      return Object.values(state.serverErrors).flat().length > 1;
    },
    someServerValidationPending(state) {
      return Object.values(state.serverValidationPending).some(
        (pending) => pending
      );
    },
    isValid(state) {
      return (
        state.validationObserver != null &&
        state.validationObserver.valid &&
        !state.validationObserver.pending &&
        !state.hasServerErrors &&
        !state.someServerValidationPending
      );
    },
    isDirty(state) {
      return state.validationObserver != null && state.validationObserver.dirty;
    },
  },
  actions: {
    async save(context) {
      const product = getProductFromForm(context);
      let savedProduct = null;
      context.commit("setSaving", true);
      try {
        //new product
        if (product?.id === 0) {
          savedProduct = await context.dispatch(
            "products/createProduct",
            product,
            {
              root: true,
            }
          );
          if (savedProduct && savedProduct.id) {
            await imageSvc.uploadBase64Image({
              imageId: savedProduct.id,
              imageType: SupplierProduct,
              imageBase64: product.imageUrl,
            });
          }
        }
        // existing product
        else if (product?.id > 0) {
          savedProduct = await context.dispatch(
            "products/updateProduct",
            product,
            {
              root: true,
            }
          );
        }
      } catch (err) {
        let errorMsg = err?.errorList[0]?.errorDescription;
        notification.error(
          `En feil oppstod ved lagring av produkt${
            errorMsg ? ": " + errorMsg : ""
          }`
        );
      } finally {
        context.commit("setSaving", false);
      }
      return savedProduct;
    },

    async deleteBarcode(context, { barcode, productId }) {
      let deleted = false;
      try {
        // Remove barcode from product
        deleted = await productSvc.deleteBarcode({ barcode, productId });
        if (deleted) {
          // Refetch product details
          const product = await context.dispatch(
            "products/fetchProductById",
            productId,
            {
              root: true,
            }
          );
          // Update barcodes in the product-form
          context.commit("set", { key: "barcodes", value: product.barcodes });
        }
      } catch (err) {
        console.error(err);
        notification.error("En feil oppstod under sletting av strekkoden");
      }
      return deleted;
    },

    async addBarcode(context, { barcode, productId }) {
      let result = false;
      try {
        // Add barcode to product
        result = await productSvc.addBarcode({ barcode, productId });
        if (result && result.success) {
          // Refetch product details
          const product = await context.dispatch(
            "products/fetchProductById",
            productId,
            {
              root: true,
            }
          );
          // Update barcodes in the product-form
          context.commit("set", { key: "barcodes", value: product.barcodes });
          notification.primary("Strekkoden ble lagret på produktet");
        } else if (result && !result.success) {
          notification.error(
            `Strekkoden eksisterer allerede på en annet produkt: ${result.alreadyExistsOnProduct.productName}`
          );
        }
      } catch (err) {
        console.error(err);
        notification.error("En feil oppstod under lagring av strekkoden");
      }
      return result;
    },

    async validateDataProviderProductId(context, dataProviderProductId) {
      let errors = [];
      try {
        if (dataProviderProductId) {
          context.commit("setServerValidationPending", {
            field: "dataProviderProductId",
            pending: true,
          });
          const product = await productSvc.getProductByDataProviderProductId(
            dataProviderProductId
          );
          if (product != null) {
            errors = [
              "Produkt-ID må være unik. IDen er allerede i bruk på produktet:  " +
                product.productName,
            ];
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        context.commit("setServerValidationPending", {
          field: "dataProviderProductId",
          pending: false,
        });
      }

      context.commit("setServerErrors", {
        field: "dataProviderProductId",
        errors,
      });

      return errors;
    },

    async validateSupplementaryNumber(context, supplementaryNumber) {
      let errors = [];
      try {
        if (supplementaryNumber) {
          context.commit("setServerValidationPending", {
            field: "supplementaryNumber",
            pending: true,
          });
          const product = await productSvc.getProductBySupplementaryNumber(
            supplementaryNumber
          );
          if (product != null) {
            errors = [
              "Artikkelnummer må være unik. Artikkelnummeret er allerede i bruk på produktet:  " +
                product.productName,
            ];
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        context.commit("setServerValidationPending", {
          field: "supplementaryNumber",
          pending: false,
        });
      }

      context.commit("setServerErrors", {
        field: "supplementaryNumber",
        errors,
      });

      return errors;
    },
  },
  mutations: {
    set(state, { key, value }) {
      state.productForm[key] = value;
    },
    setValidationObserver(state, validationObserver) {
      Vue.set(state, "validationObserver", validationObserver);
    },
    setFromProduct(state, product) {
      const emptyForm = new ProductForm();

      state.productForm.id = product?.id != null ? product?.id : emptyForm.id;

      state.productForm.activeStatus =
        product?.activeStatus != null
          ? product?.activeStatus
          : emptyForm.activeStatus;

      state.productForm.brandId =
        product?.brandId != null ? product?.brandId : emptyForm.brandId;

      state.productForm.productSeriesId =
        product?.productSeriesId != null
          ? product?.productSeriesId
          : emptyForm.productSeriesId;

      state.productForm.dataProviderProductId =
        product?.dataProviderProductId != null
          ? product?.dataProviderProductId
          : emptyForm.dataProviderProductId;

      state.productForm.imageUrl =
        product?.imageUrl != null ? product?.imageUrl : emptyForm.imageUrl;

      state.productForm.productName =
        product?.productName != null
          ? product?.productName
          : emptyForm.productName;

      state.productForm.productShortName =
        product?.productShortName != null
          ? product?.productShortName
          : emptyForm.productShortName;

      state.productForm.isSalesProduct =
        product?.isSalesProduct != null
          ? product?.isSalesProduct
          : emptyForm.isSalesProduct;

      state.productForm.inPriceExVat =
        product?.inPriceExVat != null
          ? product?.inPriceExVat
          : emptyForm.inPriceExVat;

      state.productForm.recommendedSalesPriceIncVat =
        product?.recommendedSalesPriceIncVat != null
          ? product?.recommendedSalesPriceIncVat
          : emptyForm.recommendedSalesPriceIncVat;

      state.productForm.ean = emptyForm.ean;

      state.productForm.barcodes =
        product?.barcodes != null ? product?.barcodes : emptyForm.barcodes;

      state.productForm.vat =
        product?.vat != null ? product?.vat : emptyForm.vat;

      state.productForm.productCategoryId =
        product?.productCategoryId != null
          ? product?.productCategoryId
          : emptyForm.productCategoryId;

      state.productForm.size =
        product?.size != null ? product?.size : emptyForm.size;

      state.productForm.unit =
        product?.unit != null ? product?.unit : emptyForm.unit;

      state.productForm.packageSize =
        product?.packageSize != null
          ? product?.packageSize
          : emptyForm.packageSize;

      state.productForm.minimumOrderQuantity =
        product?.minimumOrderQuantity != null
          ? product?.minimumOrderQuantity
          : emptyForm.minimumOrderQuantity;

      state.productForm.supplementaryNumber =
        product?.supplementaryNumber != null
          ? product?.supplementaryNumber
          : emptyForm.supplementaryNumber;

      state.productForm.packageEan =
        product?.packageEan != null
          ? product?.packageEan
          : emptyForm.packageEan;

      state.productForm.description =
        product?.description != null
          ? product?.description
          : emptyForm.description;

      state.productForm.shelfDescription =
        product?.shelfDescription != null
          ? product?.shelfDescription
          : emptyForm.shelfDescription;

      state.productForm.productType =
        product?.productType != null
          ? product?.productType
          : emptyForm.productType;

      state.productForm.color =
        product?.color != null ? product?.color : emptyForm.color;

      state.productForm.colorCode =
        product?.colorCode != null ? product?.colorCode : emptyForm.colorCode;

      state.productForm.priceChangeFromDate =
        product?.priceChangeFromDate != null
          ? toNullableMoment({ dt: product?.priceChangeFromDate })
          : toNullableMoment({ dt: emptyForm.priceChangeFromDate });

      state.productForm.videoUrl =
        product?.videoUrl != null ? product?.videoUrl : emptyForm.videoUrl;

      state.productForm.hasTester =
        product?.hasTester != null ? product?.hasTester : emptyForm.hasTester;

      // special handling campaign dates
      if (
        product?.campaignFromDate != null &&
        product?.campaignToDate != null
      ) {
        const today = moment().startOf("day");
        const from = moment(product.campaignFromDate);
        const to = moment(product.campaignToDate);
        if (to.isBefore(today) && from.isSame(to, "day")) {
          state.productForm.campaignFromDate = emptyForm.campaignFromDate;
          state.productForm.campaignToDate = emptyForm.campaignToDate;
        } else {
          setCampaignDates({
            product,
            productForm: state.productForm,
            emptyForm,
          });
        }
      } else {
        setCampaignDates({
          product,
          productForm: state.productForm,
          emptyForm,
        });
      }
    },

    setSaving(state, isSaving) {
      state.isSaving = isSaving;
    },
    setServerErrors(state, { field, errors }) {
      Vue.set(state.serverErrors, field, errors);
    },
    setServerValidationPending(state, { field, pending }) {
      Vue.set(state.serverValidationPending, field, pending);
    },
    resetServerErrors(state) {
      Object.keys(state.serverErrors).forEach((key) => {
        Vue.set(state.serverErrors, key, []);
      });
    },
  },
};
