<template>
  
 <div class="text-left">
  <v-card 
      v-for="item in items"
      :key="item.Id"
      class="px-8 pb-3 pt-6 mt-1 apiContentSectionCard"
      v-bind:class="{'subItem': item.properties.find(p => p.propertyTypeAlias=== 'menuLayer').content === '1'}"
      :id="`${item.urlName}`" 
      flat
      tile
      >
      <h4 class="pb-1" v-text="item.properties.find(p => p.propertyTypeAlias=== 'sectionName').content"></h4>
      <div v-html="item.properties.find(p => p.propertyTypeAlias === 'sectionContent').content" class="apiContentSectionCardContent clearfix pt-3"></div>
      <div class="endOfSection"></div>
  </v-card>
 </div>
</template>

<script>
export default {
  name: "DocumentationContentSection",
  props: {
    items: {
      type: Array,
      required: true,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>

</style>

