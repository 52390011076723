import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import wait from "./plugins/wait";
import moment from "moment";
import "./plugins/axios";
import "./components/global-components";
import "./plugins/vue-currency-filter";
import "./plugins/vee-validate";

Vue.config.productionTip = false;
Vue.prototype.moment = moment;

new Vue({
  wait,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
