const defaultVat = 25;
export default class ProductForm {
  constructor(product) {
    this.activeStatus = product?.activeStatus || true;
    this.id = product?.id || 0;
    this.dataProviderProductId = product?.dataProviderProductId || "";
    this.imageUrl = product?.imageUrl || "";
    this.brandId = product?.brandId;
    this.productSeriesId = product?.productSeriesId;
    this.productName = product?.productName || "";
    this.productShortName = product?.productShortName || "";
    this.isSalesProduct = product?.isSalesProduct || true;
    this.inPriceExVat = product?.inPriceExVat || 0;
    this.recommendedSalesPriceIncVat =
      product?.recommendedSalesPriceIncVat || 0;
    this.ean = "";
    this.barcodes = product?.barcodes || [];
    this.vat = product?.vat || defaultVat;
    this.productCategoryId = product?.productCategoryId;
    this.size = product?.size || 0;
    this.unit = product?.unit || "";
    this.packageSize = product?.packageSize || 1;
    this.minimumOrderQuantity = product?.minimumOrderQuantity || 1;
    this.supplementaryNumber = product?.supplementaryNumber || "";
    this.packageEan = product?.packageEan || "";
    this.description = product?.description || "";
    this.shelfDescription = product?.shelfDescription || "";
    this.productType = product?.productType || "";
    this.color = product?.color || "";
    this.colorCode = product?.colorCode || "";
    this.priceChangeFromDate = product?.priceChangeFromDate || null;
    this.videoUrl = product?.videoUrl || "";
    this.hasTester = product?.hasTester || false;
    this.campaignFromDate = product?.campaignFromDate || null;
    this.campaignToDate = product?.campaignToDate || null;
  }
}
