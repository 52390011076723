export async function clear({ commit }, stateNames) {
    commit('clear', stateNames);
}

export async function set({ commit }, payload) {
    commit('set', payload);
}

export default {
    clear,
    set
}