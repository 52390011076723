import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  max,
  regex,
  min_value,
  max_value,
  double,
  integer,
  numeric,
} from "vee-validate/dist/rules";
import { getProductByBarcode } from "@/services/products";

// Add a rule.
extend("required", {
  ...required,
  message: "Dette feltet er påkrevd",
});
extend("max", {
  ...max,
  message: "Maks tilatte tegn er {length}",
});
extend("regex", {
  ...regex,
  message: "Feltet inneholder ugyldige tegn",
});

extend("min_value", {
  ...min_value,
  message: "Minste tillate verdi er {min}",
});

extend("max_value", {
  ...max_value,
  message: "Største tillate verdi er {max}",
});

extend("double", {
  ...double,
  message: "Ugyldig verdi",
});

extend("integer", {
  ...integer,
  message: "Ugyldig verdi",
});

extend("numeric", {
  ...numeric,
  message: "Feltet kan kun innholde nummer",
});

extend("uniqueEan", {
  async validate(ean) {
    if (!ean) return true;
    let result = {
      product: null,
      errorMessage: "",
    };
    try {
      let product = await getProductByBarcode(ean);
      result.product = product;
    } catch (ex) {
      console.error(ex);
      result.errorMessage =
        "En feil oppstod: Klarte ikke å validere strekkoden";
    }
    if (result.errorMessage) return result.errorMessage;
    else if (result.product != null) {
      return (
        "Strekkoden er allerede i bruk på produktet: " +
        result.product.productName
      );
    }
    return true;
  },
});

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
