export default class Product {
  constructor(args) {
    this.activeStatus = args?.activeStatus || false;
    this.id = args?.id || 0;
    this.articleType = args?.articleType;
    this.brandId = args?.brandId;
    this.brandName = args?.brandName;
    this.campaignFromDate = args?.campaignFromDate;
    this.campaignToDate = args?.campaignToDate;
    this.cloudinaryImageId = args?.cloudinaryImageId;
    this.color = args?.color;
    this.colorCode = args?.colorCode;
    this.createdTime = args?.createdTime;
    this.customSize = args?.customSize;
    this.dataProviderBrandId = args?.dataProviderBrandId;
    this.dataProviderCategoryId = args?.dataProviderCategoryId;
    this.dataProviderId = args?.dataProviderId;
    this.dataProviderProductId = args?.dataProviderProductId;
    this.dataProviderProductSerieId = args?.dataProviderProductSerieId;
    this.description = args?.description;
    this.ean = args?.ean;
    this.hasPicture = args?.hasPicture;
    this.hasTester = args?.hasTester;
    this.imageFilenames = args?.imageFilenames;
    this.imageUrl = args?.imageUrl;
    this.imageTransformation = args?.imageTransformation;
    this.inPriceExVat = args?.inPriceExVat;
    this.isCampaignProduct = args?.isCampaignProduct;
    this.isExpired = args?.isExpired;
    this.isPackage = args?.isPackage;
    this.isSalesProduct = args?.isSalesProduct;
    this.minimumOrderQuantity = args?.minimumOrderQuantity;
    this.odinMasterProductId = args?.odinMasterProductId;
    this.orderAvailableFrom = args?.orderAvailableFrom;
    this.orderAvailableTo = args?.orderAvailableTo;
    this.packageEan = args?.packageEan;
    this.packageSize = args?.packageSize;
    this.prevRecommendedSalesPriceIncVat =
      args?.prevRecommendedSalesPriceIncVat;
    this.priceChangeFromDate = args?.priceChangeFromDate;
    this.productCategoryId = args?.productCategoryId;
    this.productCategoryName = args?.productCategoryName;
    this.productName = args?.productName;
    this.productSeriesId = args?.productSeriesId;
    this.productSeriesName = args?.productSeriesName;
    this.productShortName = args?.productShortName;
    this.productType = args?.productType;
    this.recommendedSalesPriceIncVat = args?.recommendedSalesPriceIncVat;
    this.revisionId = args?.revisionId;
    this.shelfDescription = args?.shelfDescription;
    this.size = args?.size;
    this.sortNo = args?.sortNo;
    this.supplementaryNumber = args?.supplementaryNumber;
    this.supplierId = args?.supplierId;
    this.supplierProductText = args?.supplierProductText;
    this.unit = args?.unit;
    this.updatedTime = args?.updatedTime;
    this.vat = args?.vat;
    this.vendorCode = args?.vendorCode;
    this.videoUrl = args?.videoUrl;
  }
}
