<template>
    <v-card class="product-card" tile v-bind="$attrs" flat :ripple="false">
      <section class="card-image-wrapper">
        <section
          class="card-tag"
          :style="{
            left: tagPosition === 'left' ? 0 : null,
            right: tagPosition === 'right' ? 0 : null,
          }"
        >
          <slot name="tag">
            <div v-if="tag" class="card-circle d-flex" :style="circleStyle">
              <div>{{ tag }}</div>
            </div>
          </slot>
        </section>
        <section class="card-image">
          <slot name="image">
            <v-img v-bind="productImage"></v-img>
          </slot>
          <div v-if="!product.activeStatus" class="availability" :class="{ 'upcoming' : productAvailability.upcoming }">
            <div> {{ productAvailability.text }} </div>
          </div>
        </section>
        
        
      </section>
  
      <section class="card-body" :style="{ justifyContent: this.justifyContent }">
        <slot>
          <slot name="title"
            ><span class="product-title" v-if="product.productName">{{
              product.productName
            }}</span>
          </slot>
          <slot name="subtitle">
            <span
              class="product-subtitle"
              v-if="product.brandName || product.serieName"
              >{{
                `${product.brandName ? product.brandName : ""} ${
                  product.brandName && product.serieName ? "/" : ""
                } ${product.serieName ? product.serieName : ""}`
              }}</span
            >
          </slot>
          <slot name="text">
            <span class="product-text" v-if="product.price != null">
              <span class="description" v-if="priceDescription"
                >{{ priceDescription }}
              </span>
              <span class="symbol" v-if="priceSymbol"
                >{{ priceSymbol }}&nbsp;</span
              >
              <span class="value">{{ currency(product.price, 2) }}</span>
            </span>
          </slot>
        </slot>
      </section>
    </v-card>
  </template>
  
  <script>
  const materialGreyLighten3 = "#EEEEEE";
  const fixitGreen = "#BFE5E8"; // the blue-green/turquoise  from the Fixit Profile
  const fixitPeach = "#EFDDD1"; // the peach color from the Fixit Profile
  
  export default {
    name: "ProductCard",
    props: {
      /**
       * @property {object}  product - The product item to be display.
       * @property {string}  product.imageUrl - URL to the product image.
       * @property {string}  product.productName - The name of the product. Used in the card title
       * @property {string}  product.brandName - The name of the product brand. Used in the card subtitle.
       * @property {string}  product.serieName - The name of the product serie. Used in the card subtitle.
       * @property {number}  product.price - The product price. Used in the card description text.
       * @property {Boolean} product.activeStatus - The active status of the product
       * @property {DateTime} product.orderAvailableFrom - The datetime string that defines where 
       * @property {DateTime} product.orderAvailableTo - The product price. Used in the card description text.
       *
       */

       
      product: {
        type: Object,
        required: true,
      },
  
      /**
       * The border color used in the gradient card border. The top color.
       */
      gradientColorFrom: {
        type: String,
        required: false,
      },
  
      /**
       * The border color used in the gradient card border. The bottom color.
       */
      gradientColorTo: {
        type: String,
        required: false,
      },
  
      /**
       * Options for the card image.
       * See https://vuetifyjs.com/en/api/v-img/ for all available props.
       */
      imageOptions: {
        type: Object,
        required: false,
      },
  
      /**
       * Justifies the card body position: flex-start, center or flex-end
       */
      justifyContent: {
        type: String,
        default: "center",
        required: false,
      },
  
      /**
       * Optional tag marking what kind of product it is.  E.g. (S, F, k) (sales, consumable, campaign product)
       */
      tag: {
        type: String,
        required: false,
      },
  
      /**
       * Override the default colors for the recognized tags.
       * Can be used for e.g product article types (sales, consumable, campaign product etc.)
       * K, S, F Tags have default colors: 
       * Default values {
          K: "#FB6863"
          S: "#FCE583"
          F: "#EFDDD1"
        }; 
       */
      tagColors: {
        type: Object,
        required: false,
      },
  
      /**
       * The position of the tag. The default is "left"
       */
      tagPosition: {
        type: String,
        required: false,
        default: "left",
        validator: (value) => {
          return ["left", "right"].includes(value);
        },
      },
  
      /**
       * Optional description in front of the product price
       */
      priceDescription: {
        type: String,
        required: false,
      },
  
      /**
       * Optionional currency symbol e.g. "kr"
       */
      priceSymbol: {
        type: String,
        required: false,
      },
    },
  
    computed: {
      /** Computes the product tag colors.
       * Can be overriden with the prop: tagColors  */
      productTagColors() {
        return {
          K: "#FB6863",
          S: "#FCE583",
          F: "#EFDDD1",
          ...this.tagColors,
        };
      },
  
      /**
       * Computes the card tag style
       */
      cardTagStyle() {
        return {
          left: this.tagPosition === "left" ? 0 : undefined,
          right: this.tagPosition === "right" ? 0 : undefined,
        };
      },
  
      /**
       * Computes product tag background color
       */
      circleStyle() {
        const tag = (this.product?.tag || "").toUpperCase();
        return {
          backgroundColor: this.productTagColors[tag],
        };
      },
  
      /**
       * Computes the image options bound to v-img tag.
       * Image options can be passed in with the component prop 'imageOptions'
       */
      productImage() {
        return {
          src: this.product.imageUrl,
          alt: this.product.productName,
          contain: true,
          ...this.imageOptions,
        };
      },
      productAvailability() {
        const today = new Date()
        const monthNames = ["Januar", "Februar", "Mars", "April", "Mai", "Juni",
          "Juli", "August", "September", "Oktober", "November", "Desember"
        ];
        const productToDate = new Date(this.product.activeStatus ? 0 : Date.parse(this.product.orderAvailableTo))
        const productFromDate = new Date(this.product.activeStatus ? 0 : Date.parse(this.product.orderAvailableFrom))
        const isUpcoming = productFromDate > today
        
        return {
          upcoming: isUpcoming,
          productFromDate: productFromDate,
          productToDate: productToDate,
          text: isUpcoming ? 'Kommer ' + productFromDate.getDay() + '.' + monthNames[productFromDate.getMonth()] + '!' : 'Utgått',
        };
      },
    },
  
    watch: {
      gradientColorTo(value) {
        this.setProperty("--gradient-color-to", value);
      },
      gradientColorFrom(value) {
        this.setProperty("--gradient-color-from", value);
      },
    },
  
    methods: {
      setProperty(prop, value) {
        this.$el.style.setProperty(prop, value);
      },
      currency(value, digits) {
        const parsedValue = Number.parseFloat(value);
        let formatted = null;
        if (!Number.isNaN(parsedValue)) {
          formatted = parsedValue
            .toFixed(digits)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
        return formatted;
      },
    },
  
    mounted() {
      this.setProperty(
        "--gradient-color-from",
        this.gradientColorFrom || fixitGreen || materialGreyLighten3
      );
      this.setProperty(
        "--gradient-color-to",
        this.gradientColorTo || fixitPeach || materialGreyLighten3
      );
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .product-card {
    --gradient-color-from: #eeeeee;
    --gradient-color-to: #eeeeee;
    display: flex;
    flex-direction: column;
  
    padding: 10px;
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      var(--gradient-color-from) 0%,
      var(--gradient-color-to) 100%
    );
  
    &.v-card--link:focus:before {
      opacity: 0 !important;
    }
  
    .card-image-wrapper {
      position: relative;
    }
    .card-tag {
      z-index: 2;
      display: flex;
      align-items: center;
      position: absolute;
      padding: 6px;
      .card-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22px;
        width: 22px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50%;
        div {
          display: block;
          margin-top: 2px;
        }
      }
    }
    .card-image {
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .availability{
        position: absolute;
        bottom: 0;
        right: 0;
        min-width: 190px;
        pointer-events: none;
        background-color: grey;
        border-radius: 10px 0 0 0;
        padding: 6px 6px 4px 6px;
        color: white;
        text-align: center;
        &.upcoming{
          background-color: #EA412A;
        }
      }
    }
    
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      flex: 1 0 auto;
      padding-top: 10px;
    }
  
    .product-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.25;
      color: black;
    }
  
    .product-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43;
      color: #a391a4;
    }
  
    .product-text {
      font-weight: bold;
      display: inline-flex;
      padding-top: 10px;
      .description {
        font-size: 12px;
        line-height: 1.17;
        margin-right: 5px;
      }
      .value,
      .symbol {
        font-size: 20px;
        line-height: 1;
      }
    }
  }
  </style>