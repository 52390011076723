const defaults = {
  snackbar: {
    absolute: false,
    autoHeight: false,
    color: undefined,
    multiLine: false,
    snackbar: false,
    text: "", // Text in snackbar default slot.
    timeout: 3000,
    vertical: false,
    showCloseButton: false,
    // X and Y for  left, right, top, bottom alignment
    x: "right",
    y: "top",
  },
};

const state = {
  snackbar: defaults.snackbar,
};

const mutations = {
  set(state, snackbar) {
    snackbar = Object(snackbar);
    state.snackbar = Object.assign({}, state.snackbar, snackbar, {
      snackbar: true,
    });
  },

  reset(state) {
    state.snackbar = Object.assign({}, defaults.snackbar);
  },
};

const getters = {
  get(state) {
    return state.snackbar;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
