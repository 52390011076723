<template>
  <div id="app">
    <v-app>
      <fixit-navigation
        :app-bar-options="{ app: true, hideOnScroll: true }"
        v-if="$route.name !== 'login'"
        :bottom-navigation-options="{ app: true }"
        :use-axios="true"
        :user-menu-items="userMenuItems"
      />
      <!--       <nav-menu v-if="$route.name !== 'login'"/>-->
      <v-main>
        <router-view />
      </v-main>
      <app-snackbar></app-snackbar>
    </v-app>
  </div>
</template>

<script>
import { FixitNavigation } from "@fixit/menu-navigation";
import NavMenu from "./components/NavMenu";
import AppSnackbar from "./components/TheSnackbar.vue";

export default {
  data() {
    return {
      userMenuItems: [
        {
          name: "Brukeradministrasjon",
          url: "https://fixitonline.no/Intranet/Admin.aspx",
          requiredRoles: ["IntranetTools"],
        },
        {
          name: "Odinadministrasjon",
          url: "https://fixitonline.no/Admin/",
          requiredRoles: ["OdinGlobalAdmin"],
        },
        {
          name: "Min Side",
          url: "https://minesider.fixitonline.no/profil",
          requiredRoles: [],
        },
        {
          name: "Logg ut",
          url:
            process.env.VUE_APP_LOGIN_SITE_URL ||
            "https://login.fixitonline.no/",
          requiredRoles: [],
        },
      ],
    };
  },
  components: { FixitNavigation, AppSnackbar },
};
</script>

<style lang="scss">
@import url("https://cdn.fixitonline.no/assets/public/styles/general.css");
#app {
  $body-font-family: "Niveau Grotesk";
  $title-font: "Niveau Grotesk";

  font-family: $body-font-family, sans-serif !important;

  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }

  main {
    .image-border-gradiented {
      --gradient-color-from: #bfe5e8;
      --gradient-color-to: #efddd1;
      border: 2px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(
        var(--gradient-color-from) 0%,
        var(--gradient-color-to) 100%
      );
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .fixit-mobile-navigation-menu {
    text-align: left;
  }

  .bg-gradient {
    background: linear-gradient(
      90deg,
      var(--v-fixitGreenLight1-base),
      var(--v-secondary-base)
    );
  }

  .word-break-all {
    word-break: break-all;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
