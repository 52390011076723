import axios from 'axios';
import ServiceCancellable from './models/ServiceCancellable';
import ServiceInterceptor from './models/ServiceInterceptor';

const baseURL = `${process.env.VUE_APP_API_URL}/api/basic-import`

// service.interceptors.request.use(ServiceCancellable.useRequest);
// service.interceptors.response.use(ServiceCancellable.useResponse, ServiceCancellable.useResponseError);
// service.interceptors.response.use(ServiceInterceptor.useResponse, ServiceCancellable.ServiceInterceptor);

export default {
    async getActiveImport() {
        return await axios.get(baseURL + '/get-active-import')
    },
    async createNewImport(queryParams) {
        return await axios.post(baseURL + '/new-import', queryParams.importOptions, {params: {fileType: queryParams.fileType}})
    },
    async updateImport(queryParams) {
        return await axios.put(baseURL + '/update-import', queryParams)
    },
    async deleteImport(queryParams) {
        return await axios.delete(baseURL + '/delete-import', {params: queryParams})
    },
    async uploadImportFile(queryParams) {
        return await axios.post(baseURL + '/upload-import-file', queryParams.file, {params: {importId: queryParams.importId}})
    },
    async mapColumns(queryParams) {
        return await axios.post(baseURL + '/map-columns', queryParams.columnMappings, {params: {importId: queryParams.importId}})
    },
    async parseAndValidateImport(queryParams) {
        return await axios.post(baseURL + '/parse-and-validate-import', null, {params: {importId: queryParams.importId}})
    },
    async getImportPreview(queryParams) {
        return await axios.get(baseURL + '/get-import-preview', {params: queryParams})
    },
    async getFixitColumns(queryParams) {
        return await axios.get(baseURL + '/get-fixit-columns')
    },
    async parseImportFileHeader(queryParams) {
        return await axios.get(baseURL + '/parse-import-file-headers', {params: queryParams})
    },
    async executeImport(queryParams) {
        return await axios.post(baseURL + '/execute-import', null, {params: {importId: queryParams.importId}})
    },
    async getImports() {
        return await axios.get(baseURL + '/get-imports')
    }
}
