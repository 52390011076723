<template>
  <div class="runway hidden-print d-print-none">
    <div class="runway-container">
      <div class="runway-media-left">
        <a class="runway-menu-btn runway-hamburger" href="#extras" role="button">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="runway-hamburger-title">Meny</span>
        </a>
      </div>
      <div class="runway-media-body">
        <a class="runway-logo" href="/">
          <img alt="fixit online" height="46" src="../assets/FixIT_Online_logo_svart.svg">
        </a>
      </div>
      <div class=" runway-media-right">
        <ul v-if="user" id="js-menu-fixed" class="nav navbar-nav nav-user">
          <li class="dropdown pull-right">
            <a class="runway-menu-btn" href="#profile" role="button">
              <div class="profile-image-container">
                <div class="profile-image-content">
                  <img :src="user.imageUrl ? user.imageUrl.replace('~','') : require('../assets/person_unknown.png')"
                       alt="profile pic">
                </div>
              </div>
              <small class="nav-user-caption">Velkommen</small>
              <strong class="nav-user-name">{{ user.firstName }} {{ user.lastName }}</strong>
              <b class="caret"></b>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <ul id="extras" class="runway-menu">
      <template v-if="topLevel">
        <template v-for="item in topLevel">
          <template v-if="subMenuItems(item).length">
            <li :class="isCurrentMenuItem(item) ? 'active' : ''" v-bind:key="item.id" >
              <a :href="item.url">{{ item.title }}</a>
              <span
                  :class="'subMenuButton' + (isCurrentMenuItem(item) ? ' fixitonline-icon-chevron-up' : ' fixitonline-icon-chevron-down')"
                  :data-submenuid="(item.id)+'SubMenu'">{{ isCurrentMenuItem(item) ? '&#9650;' : '&#9660;' }}</span>

              <div class="clearBoth clearfix"></div>

              <ul :id="item.id + 'SubMenu'" :class="'runway-submenu' + (isCurrentMenuItem(item) ? ' in' : '')">

                <template v-for="(subMenuItem) in subMenuItems(item)">
                  <li :id="subMenuItem.key" :class="isCurrentMenuItem(subMenuItem) ? 'active' : ''" v-bind:key="subMenuItem.key">
                    <a :href="subMenuItem.url">{{ subMenuItem.title }}</a>
                  </li>
                  <!-- <hr/> -->
                </template>
              </ul>
            </li>
          </template>
          <template v-else>
            <li :id="item.key" :class="isCurrentMenuItem(item) ? ' in' : ''" v-bind:key="item.key">
              <a :href="item.url" class="noChildren">{{ item.title }}</a>
              <div class="clearBoth clearfix"></div>
            </li>
          </template>
        </template>
      </template>


    </ul>

    <ul id="profile" class="runway-menu" v-if="user">
      <li>
        <div class="user-profile-top-links">
          <img :src="user.imageUrl ? user.imageUrl.replace('~','') : require('../assets/person_unknown.png')"
               alt="profile pic" class="img-responsive"
               onerror="this.onerror = null; this.src = require('../assets/person_unknown.png');" width="250">
          <ul>
            <li>
              <h4 class="user-profile-full-name">{{ user.firstName }} {{ user.lastName }}</h4>
            </li>

            <li v-if="userHasRole('ChainAdministrator') && getElementByKey('AdminListUsers')">
              <a class="noChildren" :href="getElementByKey('AdminListUsers').url">{{ getElementByKey('AdminListUsers').title }}</a>
              <div class="clearBoth clearfix"></div>
            </li>

            <li v-if="(userHasRole('IntranetTools') || userHasRole('CoachTools')) && getElementByKey('User')">
              <a class="noChildren" :href="getElementByKey('User').url">{{ getElementByKey('User').title }}</a>
              <div class="clearBoth clearfix"></div>
            </li>

            <li v-if="userHasRole('OdinAdmin') && getElementByKey('Admin')">
              <a class="noChildren" :href="getElementByKey('Admin').url">{{ getElementByKey('Admin').title }}</a>
              <div class="clearBoth clearfix"></div>
            </li>

            <li v-if="userHasRole('Supplier') && getElementByKey('SupplierSettings')">
              <a class="noChildren" :href="getElementByKey('SupplierSettings').url">{{ getElementByKey('SupplierSettings').title }}</a>
              <div class="clearBoth clearfix"></div>
            </li>

            <li v-if="getElementByKey('UserProfile')">
              <a class="noChildren" :href="getElementByKey('UserProfile').url">{{ getElementByKey('UserProfile').title }}</a>
              <div class="clearBoth clearfix"></div>
            </li>

            <li v-if="user">
              <a class="noChildren" @click="logout">Logg ut</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>


<style>
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
</style>
<script>

import axios from "axios";

export default {
    name: "NavMenu",
    data() {
        return {
            isExpanded: false,
            user: null,
            siteList: []
        }
    },
    methods: {
        logout() {
            window.location.href = '/Account/Login'
            document.body.classList.remove('runway-menu-open')
            document.querySelector('.runway-backdrop').remove()
        },

        collapse() {
            this.isExpanded = false;
        },

        toggle() {
            this.isExpanded = !this.isExpanded;
        }
    },
    computed: {
        userHasRole() {
            return role => this.user.userRolesList.find(x => x.name.toLowerCase() === role.toLowerCase())
        },
        getElementByKey() {
            return key => this.siteList.find(x => x.key.toLowerCase() === key.toLowerCase())
        },
        isCurrentMenuItem() {
            return item => item.key === 'FixitOnlineApollo'
        },
        subMenuItems() {
            return item => this.siteList.filter(s => s.parentId === item.id && s.isMenuElement)
        },
        topLevel() {
            return this.siteList.filter(x => x.parentId === 0 && x.isMenuElement);
        }
    },
    watch: {
        user: {
            handler() {
                this.$nextTick(() => {
                    let $backdrop = null;

                    //------------------------------------------------------//
                    //== RUNWAY BACKDROP ===================================//
                    let backdrop = function () {
                        if (document.body.classList.contains('runway-menu-open')) backdrop.hide();
                        else backdrop.show();
                    };

                    backdrop.show = function () {
                        $backdrop = document.createElement('div');
                        $backdrop.classList.add('runway-backdrop', 'fade');
                        document.body.appendChild($backdrop);
                        document.body.classList.add('runway-menu-open');
                        setTimeout(function () {
                            $backdrop.classList.add('in')
                        }, 0);
                    };

                    backdrop.hide = function (event) {
                        if ($backdrop && (!event || event.target.classList.contains('runway-backdrop'))) {
                            $backdrop.classList.remove('in');
                            document.body.classList.remove('runway-menu-open');
                            document.body.querySelectorAll('.runway-menu').forEach(function (rm) {
                                rm.classList.remove('in')
                            });

                            // Delete backdrop
                            document.querySelectorAll('.runway-backdrop').forEach(function (bd) {
                                setTimeout(function () {
                                    bd.remove();
                                }, 500);

                                $backdrop = null;
                            });
                        }
                    };

                    document.addEventListener('click', backdrop.hide);

                    //------------------------------------------------------//
                    //== RUNWAY MENU =======================================//
                    document.querySelectorAll('.runway-menu-btn').forEach(function (rmb) {
                        rmb.addEventListener('click', function (event) {
                            event.preventDefault();
                            event.stopPropagation();

                            let $target = document.querySelector(this.hash);
                            if ($target.classList.contains('in')) {
                                $target.classList.remove('in');
                                backdrop.hide();
                                return;
                            }

                            // TODO: Bad practice to use z-index of 9999. Should be fixed in the future.
                            let $runwayMenu = document.querySelector('.runway-menu.in');
                            if ($runwayMenu) {
                                $runwayMenu.style.zIndex = 9998;
                                $runwayMenu.classList.remove('in');
                            }
                            $target.style.zIndex = 9999;

                            // Positioning
                            $target.style.right = '';
                            $target.style.left = '';
                            if (document.body.getBoundingClientRect().width >= 768) {
                                let $parent = event.currentTarget;
                                if ($target.id === 'profile') $target.style.right = (document.body.getBoundingClientRect().width - ($parent.getBoundingClientRect().left + $parent.getBoundingClientRect().width)) + 'px';
                                else $target.style.left = $parent.getBoundingClientRect().left + 'px';
                            } else {
                                $target.style.left = 0;
                            }

                            // Fade in
                            $target.classList.add('in');
                            if (!document.body.classList.contains('runway-menu-open')) backdrop.show();
                        });
                    });

                    document.addEventListener('click', function (event) {
                        if (event.target.classList.contains('subMenuButton')) {
                            // Prevent click bubling which would close the main menu
                            event.preventDefault();
                            event.stopPropagation();

                            // Get the submenu button which was clicked
                            let $sender = event.target;
                            if ($sender.getAttribute('data-submenuid')) {
                                // Get submenu which should be shown
                                let $subMenu = document.getElementById($sender.getAttribute('data-submenuid'));

                                // Reset chevron status
                                document.querySelectorAll('.subMenuButton').forEach(function (sbm) {
                                    sbm.innerHTML = '&#9660;'
                                });

                                // Hide target submenu
                                if ($subMenu.classList.contains('in')) {
                                    $subMenu.classList.remove('in');
                                    $subMenu.parentElement.querySelector('.subMenuButton').innerHTML = '&#9660;';
                                }

                                // Else, show target submenu and hide the rest
                                else {
                                    // Hide all submenus
                                    document.querySelectorAll('.runway-submenu').forEach(function (sbm) {
                                        sbm.classList.remove('in');
                                    });

                                    // Show target submenu
                                    $subMenu.classList.add('in');
                                    $subMenu.parentElement.querySelector('.subMenuButton').innerHTML = '&#9650;';
                                }
                            }
                        }
                    });

                    //------------------------------------------------------//
                    //== RUNWAY HIDE/REVEAL ================================//
                    let prevSt = 0;

                    //Simple throttle function without dependencies
                    let throttle = function (callback, limit) {
                        let wait = false;                  // Initially, we're not waiting
                        return function () {               // We return a throttled function
                            if (!wait) {                   // If we're not waiting
                                callback.call();           // Execute users function
                                wait = true;               // Prevent future invocations
                                setTimeout(function () {   // After a period of time
                                    wait = false;          // And allow future invocations
                                }, limit);
                            }
                        }
                    }

                    window.addEventListener('scroll', throttle(function () {
                        let st = window.pageYOffset,
                            sd = prevSt - st,
                            bottomOffset = 100,
                            dHeight = document.body.offsetHeight,
                            wHeight = window.innerHeight;

                        if (st <= 0) document.body.classList.remove('runway-hidden'); //Reveal if on top
                        else if (sd > 0 && document.body.classList.contains('runway-hidden')) document.body.classList.remove('runway-hidden'); //Reveal if user scrolls up
                        else if (sd < 0) {
                            if (st + bottomOffset >= dHeight - wHeight) document.body.classList.remove('runway-hidden'); //Reveal if user is at bottom of page
                            else document.body.classList.add('runway-hidden'); //Hide if user scrolls down
                        }

                        prevSt = st;
                    }, 50));
                })
            }
        }
    },
    async created() {
        let userResponse = await axios.get(`${process.env.VUE_APP_FIXIT_CORE_API_URL}/api/authorization/GetCurrentUser`)
        let siteListResponse = await axios.get(`${process.env.VUE_APP_FIXIT_CORE_API_URL}/api/authorization/GetSiteListForNavigation`)
        this.user = userResponse.data?.dto
        this.siteList = siteListResponse.data?.dto
    }
}
</script>
<style>
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.runway {
  position: fixed;
  width: 100%;
  height: 70px;
  border-bottom: solid 1px #a2a2a2;
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 6px;
  margin-bottom: 0;
  z-index: 200;
  font-size: 14px;
  line-height: 1.428571429;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.runway .runway-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 767px) and (min-width: 480px) {
  .runway .runway-container {
    max-width: 748px;
  }
}

@media (min-width: 768px) {
  .runway .runway-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .runway .runway-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .runway .runway-container {
    width: 1170px;
  }
}

.runway .runway-media-left,
.runway .runway-media-right,
.runway .runway-media-body {
  display: table-cell;
  vertical-align: bottom;
}

.runway .runway-media-left {
  padding-right: 10px;
  vertical-align: top;
}

.runway .runway-media-right {
  padding-left: 10px;
}

.runway .runway-media-body {
  text-align: center;
  width: 10000px;
  zoom: 1;
  overflow: hidden;
}

.runway .runway-logo {
  line-height: 20px;
}

.runway .runway-hamburger {
  display: block;
  position: relative;
  padding: 18px 0;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .runway .runway-hamburger {
    padding: 10px 0;
  }
}

@media screen and (min-width: 480px) {
  .runway .runway-hamburger {
    width: 80px;
  }
}

.runway .runway-hamburger .runway-hamburger-title {
  float: right;
  margin-top: -24px;
  color: #000000;
}

@media screen and (max-width: 479px) {
  .runway .runway-hamburger .runway-hamburger-title {
    display: none;
  }
}

.runway .runway-hamburger .icon-bar {
  background-color: #000000;
  display: block;
  width: 32px;
  height: 6px;
  border-radius: 1px;
  top: 0;
}

.runway .runway-hamburger .icon-bar + .icon-bar {
  margin-top: 4px;
}

.runway .runway-hamburger:hover .icon-bar,
.runway .runway-hamburger:focus .icon-bar {
  background-color: #7dc5c9;
}

.runway .runway-hamburger:hover .runway-hamburger-title,
.runway .runway-hamburger:focus .runway-hamburger-title {
  color: #7dc5c9;
}

.runway .shoppingcart-placeholder {
  min-width: 34px;
  min-height: 34px;
}

@media (min-width: 767px) {
  .runway .runway-logo {
    line-height: 56px;
  }

  .runway .desktop {
    display: block;
  }
}

@media (max-width: 767px) {
  .runway {
    height: 48px;
    padding-top: 0;
  }

  .runway .runway-logo {
    line-height: 40px;
  }

  .runway .runway-logo img {
    height: 28px;
  }

  .runway .desktop {
    display: none;
  }

  .runway.bar {
    visibility: hidden;
  }
}

.runway ul.nav {
  position: relative;
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}

.runway ul.nav.nav-user {
  padding-bottom: 6px;
}

@media screen and (max-width: 767px) {
  .runway ul.nav.nav-user {
    padding-bottom: 0;
  }
}

.runway ul.nav.nav-user .runway-menu-btn {
  padding-bottom: 6px;
  padding-top: 6px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .runway ul.nav.nav-user .runway-menu-btn {
    margin: 0;
    padding: 2px;
    border-left: 0;
  }
}

.runway ul.nav.nav-user .runway-menu-btn .profile-image-container {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: relative;
}

.runway ul.nav.nav-user .runway-menu-btn .profile-image-container .profile-image-content,
.runway ul.nav.nav-user .runway-menu-btn .profile-image-container img {
  height: 100%;
}

.runway ul.nav.nav-user .runway-menu-btn .nav-user-caption {
  position: absolute;
}

@media (max-width: 767px) {
  .runway ul.nav.nav-user .runway-menu-btn .nav-user-caption {
    display: none;
  }
}

.runway ul.nav.nav-user .runway-menu-btn .nav-user-name {
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .runway ul.nav.nav-user .runway-menu-btn .nav-user-name {
    display: none;
  }
}

.runway ul.nav.nav-user .runway-menu-btn .caret {
  margin-bottom: -16px;
}

@media (max-width: 767px) {
  .runway ul.nav.nav-user .runway-menu-btn .caret {
    margin-left: -14px;
    margin-bottom: -26px;
  }
}

.runway .nav > li {
  float: left;
}

.runway .nav > li a {
  position: relative;
  display: block;
  color: #000000;
  line-height: 20px;
  text-decoration: none;
}

@media (min-width: 767px) {
  .runway .nav > li a {
    padding: 6px 15px;
  }
}

.runway .nav > li a:not(.shopping-cart-widget) {
  background: transparent;
}

.runway .nav > li a:hover,
.runway .nav > li a:focus {
  color: #808080;
}

.runway .nav > li a > b {
  color: #000000;
}

.runway .nav > li b,
.runway .nav > li strong {
  font-weight: bold;
}

.runway .nav > li.active a:hover,
.runway .nav > li.active a:focus,
.runway .nav > li.active a {
  background-color: #7dc5c9;
}

.runway .nav > li .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.runway-menu {
  position: fixed;
  list-style: none;
  display: none;
  z-index: 9999;
  text-align: left;
  background-color: #fefefe;
  margin: 0;
  padding: 3px !important;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.428571429;
  max-height: calc(100% - 48px);
  overflow-y: auto;
  border: 1px solid #cccccc;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.runway-menu a {
  text-decoration: none;
  background-color: transparent;
  color: #7dc5c9;
  cursor: pointer;
}

.runway-menu a:hover,
.runway-menu a:focus {
  background-color: #d6d8da;
}

.runway-menu a:active,
.runway-menu a:hover {
  outline: 0;
}

@media (max-width: 767px) {
  .runway-menu a.runway-menu-btn {
    margin: 4px 0;
  }
}

@media (min-width: 767px) {
  .runway-menu {
    top: 70px;
  }
}

@media (max-width: 767px) {
  .runway-menu {
    left: 0;
    right: 0;
    top: 48px;
  }
}

.runway-menu hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

.runway-menu hr.row {
  margin-left: -15px;
  margin-right: -15px;
}

.runway-menu li {
  display: block;
  width: 250px;
}

.runway-menu li + li {
  margin-top: 2px;
}

.runway-menu li.active a,
.runway-menu li.active .subMenuButton {
  background-color: #7dc5c9;
  color: #fefefe;
}

.runway-menu li.active a:hover,
.runway-menu li.active .subMenuButton:hover,
.runway-menu li.active a:focus,
.runway-menu li.active .subMenuButton:focus {
  background-color: #7dc5c9;
}

.runway-menu li.active ul.runway-submenu a {
  color: #000;
}

.runway-menu li.active ul.runway-submenu a:hover {
  color: #000;
  background: 0;
}

.runway-menu li ul.runway-submenu {
  display: none;
  padding: 0;
}

.runway-menu li ul.runway-submenu.in {
  display: block;
}

.runway-menu li ul.runway-submenu li {
  padding: 0 0 0 30px;
  width: 230px;
}

.runway-menu li ul.runway-submenu li a {
  background-color: #FFF;
  padding-left: 0;
  float: none;
}

.runway-menu li ul.runway-submenu li a:hover {
  text-decoration: underline;
}

.runway-menu li ul.runway-submenu li.active a {
  color: #7dc5c9;
  background: #FFF;
  font-weight: bold;
}

.runway-menu li ul.runway-submenu li.active a:hover {
  text-decoration: none;
  color: #7dc5c9;
}

.runway-menu li ul.runway-submenu hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e3e5e6;
  margin: 0;
  margin-left: 20px;
  padding: 0;
  background: 0;
}

.runway-menu li > a {
  position: relative;
  display: block;
  padding: 6px 15px;
  background-color: #e3e5e6;
  color: #000000;
  float: left;
  width: 89%;
}

.runway-menu li > a.noChildren {
  width: 100%;
}

.runway-menu li > h4 {
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin: 10px;
  font-size: 18px;
}

.runway-menu li .subMenuButton {
  float: right;
  padding: 6px;
  background-color: #e3e5e6;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  width: 11%;
  top: 0;
}

.runway-menu li .subMenuButton:hover,
.runway-menu li .subMenuButton:focus {
  background-color: #d6d8da;
}

.runway-menu li img {
  border: 0;
  vertical-align: middle;
}

.runway-menu li img.img-responsive {
  display: block;
  height: auto;
}

.runway-menu#profile .user-profile-top-links ul {
  padding-left: 0;
}

.runway-menu :last-child a {
  border-bottom: none;
}

.runway-menu.in {
  display: block;
}

.runway-hidden .runway {
  -webkit-transform: translateY(-70px);
  -ms-transform: translateY(-70px);
  -moz-transform: translateY(-70px);
  -o-transform: translateY(-70px);
  transform: translateY(-70px);
}

@media (max-width: 767px) {
  .runway-hidden .runway {
    -webkit-transform: translateY(-48px);
    -ms-transform: translateY(-48px);
    -moz-transform: translateY(-48px);
    -o-transform: translateY(-48px);
    transform: translateY(-48px);
  }
}

.runway-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 199;
  background-color: #000000;
}

.runway-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
  -webkit-transition: opacity .15s linear;
  -moz-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.runway-backdrop.in {
  opacity: .5 !important;
  filter: alpha(opacity=50) !important;
  /* For IE8 and earlier */
}

.runway-menu-open {
  overflow: hidden;
}

.runway img {
  vertical-align: middle;
  border-style: none;
}

.runway .clearfix::after {
  display: block;
  clear: both;
  content: "";
}

@media print {
  .runway.hidden-print,
  .runway.d-print-none {
    display: none !important;
  }
}
</style>
