import axios from "axios";
import ServiceException from "@/models/ServiceException";

const baseURL = `${process.env.VUE_APP_API_URL}/api`;
const productCatalgApi = `${process.env.VUE_APP_PRODUCT_CATALOG_API_URL}/api`;

export const getProductById = async (id) => {
  if (!id) return null;
  const responseList = await Promise.all([
    axios.get(`${baseURL}/products/${id}`),
    axios.get(`${baseURL}/products/${id}/barcodes`),
  ]);

  const response = responseList[0];
  const eanResponse = responseList[1];
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  if (eanResponse?.data?.responseCode !== 200) {
    throw new ServiceException(eanResponse?.data);
  }
  return { ...response.data.result, barcodes: eanResponse.data.result };
};

export const getProductByBarcode = async (barcode) => {
  if (!barcode) return null;
  const responseList = await Promise.all([
    axios.get(`${baseURL}/products/barcode/${barcode}`),
  ]);

  const response = responseList[0];
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response.data.result;
};

export const getProductByDataProviderProductId = async (
  dataProviderProductId
) => {
  if (!dataProviderProductId) return null;
  const responseList = await Promise.all([
    axios.get(
      `${baseURL}/products/dataProviderProductId/${dataProviderProductId}`
    ),
  ]);

  const response = responseList[0];
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response.data.result;
};

export const getProductBySupplementaryNumber = async (supplementaryNumber) => {
  if (!supplementaryNumber) return null;
  const responseList = await Promise.all([
    axios.get(`${baseURL}/products/supplementaryNumber/${supplementaryNumber}`),
  ]);

  const response = responseList[0];
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response.data.result;
};

export const getProductsBySerieId = async (serieId) => {
  if (!serieId) return [];
  const response = await axios.get(`${baseURL}/series/${serieId}/products`);
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response.data.result;
};

export const getProductsBySupplierId = async (supplierId) => {
  const response = await axios.get(
    `${productCatalgApi}/suppliers/${supplierId}/products`
  );
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response?.data.data;
};

export const fetchProductCounts = async (supplierId) => {
  const response = await axios.get(
    `${productCatalgApi}/suppliers/${supplierId}/products/counts`
  );
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response?.data.data;
};

export const createProduct = async (product) => {
  if (!product) return false;
  const response = await axios.post(`${baseURL}/products`, product);
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response.data.result;
};

export const updateProduct = async (product) => {
  if (!product) return false;
  const response = await axios.put(
    `${baseURL}/products/${product.id}`,
    product
  );
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }

  return response.data.result;
};

export const deleteBarcode = async ({ barcode, productId }) => {
  if (!productId || !barcode) return false;
  const response = await axios.delete(
    `${baseURL}/products/${productId}/barcode/${barcode}`
  );
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response.data.result;
};

export const addBarcode = async ({ barcode, productId }) => {
  if (!productId || !barcode) return false;

  const response = await axios.put(
    `${baseURL}/products/${productId}/barcode/${barcode}`
  );
  if (response?.data?.responseCode !== 200) {
    throw new ServiceException(response?.data);
  }
  return response.data.result;
};

export default {
  getProductById,
  getProductByDataProviderProductId,
  getProductBySupplementaryNumber,
  getProductByBarcode,
  getProductsBySerieId,
  getProductsBySupplierId,
  createProduct,
  updateProduct,
  deleteBarcode,
  addBarcode,
  fetchProductCounts,
};
