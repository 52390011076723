export const Appointment = 1;
export const Customer = 2;
export const Employee = 3;
export const News = 4;
export const Salon = 5;
export const Chain = 6;
export const Campaign = 7;
export const TimebokBesok = 8;
export const SharingImage = 9;
export const CampaignBubbleType = 10;
export const SupplierBrand = 11;
export const SupplierSerie = 12;
export const SupplierProduct = 13;
export const Resources = 14;
export const Fixitno = 15;
export const SalonLogo = 16;
export const HomepageBannerChain = 17;
export const FdmCampaign = 18;
export const HomepageBannerSalon = 19;
export const OwnBrand = 20;
export const OwnSerie = 21;
export const OwnProduct = 22;

export default {
  Appointment,
  Customer,
  Employee,
  News,
  Salon,
  Chain,
  Campaign,
  TimebokBesok,
  SharingImage,
  CampaignBubbleType,
  SupplierBrand,
  SupplierSerie,
  SupplierProduct,
  Resources,
  Fixitno,
  SalonLogo,
  HomepageBannerChain,
  FdmCampaign,
  HomepageBannerSalon,
  OwnBrand,
  OwnSerie,
  OwnProduct,
};
