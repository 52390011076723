import mutations from "@/store/mutations";
import SupplierService from "@/services/suppliers";

export default {
  namespaced: true,
  state: {
    currentSupplier: null,
    currentSupplierLevel: null,
    hasFetchedCurrentSupplier: false,
  },
  actions: {
    async fetchCurrentSupplier({ commit }) {
      const currentSupplier = await SupplierService.getCurrentSupplier();
      commit("set", {
        key: "hasFetchedCurrentSupplier",
        value: true,
      });
      if (currentSupplier) {
        commit("set", {
          key: "currentSupplier",
          value: currentSupplier,
        });
        if (currentSupplier.apiIntegrationExtended){ commit("set", { key: "currentSupplierLevel", value: 2 } )} 
        else if (currentSupplier.providerType == 2){ commit("set", { key: "currentSupplierLevel", value: 1 })} 
        else { commit("set", { key: "currentSupplierLevel", value: 0 } )}
      }
    },

    async getCurrentSupplier({ state, dispatch }) {
      if (!state.hasFetchedCurrentSupplier) {
        await dispatch("fetchCurrentSupplier");
      }
      return state.currentSupplier;
    },
  },
  mutations: {
    ...mutations,
  },
};
