import { render, staticRenderFns } from "./Products.vue?vue&type=template&id=7bc44702&scoped=true&"
import script from "./Products.vue?vue&type=script&lang=js&"
export * from "./Products.vue?vue&type=script&lang=js&"
import style0 from "./Products.vue?vue&type=style&index=0&id=7bc44702&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bc44702",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCol,VContainer,VDataIterator,VRow,VSheet,VTextField,VTreeview})
