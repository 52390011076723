import mutations from "@/store/mutations";
import SeriesService from "@/services/series";
import keyBy from "lodash.keyby";
import orderBy from "lodash.orderby";
import imageSvc from "@/services/images";
import { SupplierSerie } from "@/models/enums/ImageTypes";

export default {
  namespaced: true,
  state: {
    allSeriesFetchedForBrand: {}, // keep track if all series in a brand are fetched. key: brandId, value: true/false
    series: {}, // a dictionary with serie.id as key and serie object as value
  },
  getters: {
    getSeriesByBrandId: (state) => (brandId) => {
      const series = state.allSeriesFetchedForBrand[brandId]
        ? Object.values(state.series)
        : [];
      const seriesInBrand = series.filter((serie) => serie.brandId === brandId);
      return orderBy(seriesInBrand, "name");
    },
    getSerieById: (state) => (id) => {
      return state.series[id];
    },
    hasFetchedSeriesForBrand: (state) => (brandId) => {
      return state.allSeriesFetchedForBrand[brandId] || false;
    },
  },
  actions: {
    async fetchSeries({ commit }, brandId) {
      const series = await SeriesService.getSeriesByBrandId(brandId);
      commit("set", {
        key: "allSeriesFetchedForBrand",
        value: { [brandId]: true },
      });
      commit("addOrUpdate", series);
    },
    async fetchSerie({ commit }, id) {
      const serie = await SeriesService.getSerieById(id);
      commit("addOrUpdate", serie);
    },
    async updateSerie({ commit }, serie) {
      const updatedSerie = await SeriesService.updateSerie(serie);
      if (updatedSerie) {
        commit("addOrUpdate", updatedSerie);
      }
      return updatedSerie;
    },

    async createSerie({ commit }, { serie, image }) {
      if (!serie) return null;
      let createdSerie = await SeriesService.createSerie(serie);
      if (createdSerie && image) {
        await imageSvc.uploadBase64Image({
          imageId: createdSerie.id,
          imageType: SupplierSerie,
          imageBase64: image,
        });
      }
      if (createdSerie) {
        commit("addOrUpdate", createdSerie);
      }
      return createdSerie;
    },
  },
  mutations: {
    ...mutations,
    addOrUpdate(state, series) {
      if (series) {
        state.series = { ...state.series, ...keyBy([].concat(series), "id") };
      }
    },
  },
};
