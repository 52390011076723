import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@/assets/styles/app.scss'
import no from 'vuetify/es5/locale/no';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                // Theme
                primary: '#472449',
                secondary: '#EFDDD1',
                fixitPurple: "#472449",
                fixitGreen: "#82BCC0",
                fixitGreenLight1: "#BFE5E8",
                fixitGreenLight2: "#DFF2F4",
                fixitBrown: "#DCB499",
                fixitBrownLight1: "#EFDDD1",
                fixitBrownLight2: "#F8EFE8",
                fixitYellow: "#FDD77E",
                fixitYellowLight1: "#FCE583",
                fixitYellowLight2: "#FDF2C1",
                fixitRed: "#EA412A",

                'primary-bg': '#DEF1F3'
            }
        }
    },
    lang: {
        locales: { no },
        current: 'no',
    },
});
