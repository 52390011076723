import {waitFor} from "vue-wait/src/helpers";

export default {
    data() {
        return {}
    },
    computed: {
        basicImport() {
            return this.$store.getters['basic-import/get']('basicImport')
        }
    },
    methods: {
        async getActiveImport() {
            await waitFor('loading basic-import get-active-import', async () => {
                await this.$store.dispatch('basic-import/getActiveImport')
            }).apply(this)
        }
    }
}
