const filterStringMapToCountName = {
  // All product types
  FS012: "products",
  FS0: "expiredProducts",
  FS1: "activeProducts",
  FS2: "comingProducts",
  FS01: "activeOrExpiredProducts",
  FS12: "activeOrComingProducts",
  FS02: "expiredOrComingProducts",
  // Sales products
  S012: "salesProducts",
  S0: "salesExpiredProducts",
  S1: "salesActiveProducts",
  S2: "salesComingProducts",
  S01: "salesActiveOrExpiredProducts",
  S12: "salesActiveOrComingProducts",
  S02: "salesExpiredOrComingProducts",
  // Consumable products
  F012: "consumableProducts",
  F0: "consumableExpiredProducts",
  F1: "consumableActiveProducts",
  F2: "consumableComingProducts",
  F01: "consumableActiveOrExpiredProducts",
  F12: "consumableActiveOrComingProducts",
  F02: "consumableExpiredOrComingProducts",
};
export default filterStringMapToCountName;
