import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import NotFound from "@/views/errors/404NotFound";
import Unauthorized from "@/views/errors/401Unauthorized ";
import Home from "../views/homepage/Home";
import Login from "../views/Login.vue";
import Products from "../views/products/Products.vue";
import BasicImport from "../views/basic-import/BasicImport";
import BasicImportWizard from "../views/basic-import/views/wizard/BasicImportWizard";
import Documentation from "../views/api-documentation/ApiDocumentation";
import security from "@/utils/security";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/account/login",
    name: "login",
    component: Login,
  },
  {
    path: "/api-documentation",
    name: "ApiDocumentation",
    component: Documentation,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/basic-import",
    name: "BasicImport",
    component: BasicImport,
    children: [
      {
        path: "create-new",
        name: "BasicImport.CreateNew",
        component: BasicImportWizard,
      },
      {
        path: "columns-mapping",
        name: "BasicImport.ColumnsMapping",
        component: BasicImportWizard,
      },
      {
        path: "preview",
        name: "BasicImport.Preview",
        component: BasicImportWizard,
      },
      {
        path: "summary",
        name: "BasicImport.Summary",
        component: BasicImportWizard,
      },
    ],
  },
  {
    path: "/brands",
    name: "Brands",
    meta: {
      roles: ["Supplier", "Agent"],
    },
    component: () =>
      import(/* webpackChunkName: "brands" */ "@/views/brands/Brands.vue"),
  },

  {
    path: "/brand/create",
    name: "BrandCreate",
    component: () =>
      import(/* webpackChunkName: "brands" */ "@/views/brands/BrandCreate.vue"),
  },
  {
    path: "/brand/:id(\\d+)",
    name: "Brand",
    meta: {
      roles: ["Supplier", "Agent"],
    },
    component: () =>
      import(/* webpackChunkName: "brands" */ "@/views/brands/Brand.vue"),
    props: (route) => {
      return {
        id: Number.isNaN(route.params.id)
          ? 0
          : Number.parseInt(route.params.id),
        adminMode: store.state.authorization.userRole == "Supplier",
      };
    },
  },
  {
    path: "/brand/:id(\\d+)/update",
    name: "BrandUpdate",
    meta: {
      roles: ["Supplier"],
    },
    component: () =>
      import(/* webpackChunkName: "brands" */ "@/views/brands/BrandUpdate.vue"),
    props: (route) => {
      return {
        id: Number.isNaN(route.params.id)
          ? 0
          : Number.parseInt(route.params.id),
      };
    },
  },
  {
    path: "/serie/:id(\\d+)",
    name: "Serie",
    meta: {
      roles: ["Supplier", "Agent"],
    },
    component: () =>
      import(/* webpackChunkName: "series" */ "@/views/series/Serie.vue"),
    props: (route) => {
      return {
        id: Number.isNaN(route.params.id)
          ? 0
          : Number.parseInt(route.params.id),
        adminMode: store.state.authorization.userRole == "Supplier",
      };
    },
  },
  {
    path: "/serie/create",
    name: "SerieCreate",
    meta: {
      roles: ["Supplier"],
    },
    component: () =>
      import(/* webpackChunkName: "series" */ "@/views/series/SerieCreate.vue"),
    props: (route) => {
      return {
        brandId: Number.isNaN(route.query.brandId)
          ? 0
          : Number.parseInt(route.query.brandId),
      };
    },
  },
  {
    path: "/serie/:id/update",
    name: "SerieUpdate",
    meta: {
      roles: ["Supplier"],
    },
    component: () =>
      import(/* webpackChunkName: "series" */ "@/views/series/SerieUpdate.vue"),
    props: (route) => {
      return {
        id: Number.isNaN(route.params.id)
          ? 0
          : Number.parseInt(route.params.id),
      };
    },
  },
  {
    path: "/product/:id(\\d+)",
    name: "Product",
    meta: {
      roles: ["Supplier", "Agent"],
    },
    component: () =>
      import(/* webpackChunkName: "products" */ "@/views/products/Product.vue"),
    props: (route) => {
      return {
        id: Number.isNaN(route.params.id)
          ? 0
          : Number.parseInt(route.params.id),
        adminMode: store.state.authorization.userRole == "Supplier",
      };
    },
  },
  {
    path: "/product/:id(\\d+)/update",
    name: "ProductUpdate",
    meta: {
      roles: ["Supplier"],
    },
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/ProductUpdate.vue"
      ),
    props: (route) => {
      return {
        id: Number.isNaN(route.params.id)
          ? 0
          : Number.parseInt(route.params.id),
      };
    },
  },
  {
    path: "/product/create",
    name: "ProductCreate",
    meta: {
      roles: ["Supplier"],
    },
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/ProductCreate.vue"
      ),
    props: (route) => {
      return {
        brandId: Number.isNaN(route.query.brandId)
          ? 0
          : Number.parseInt(route.query.brandId),
        serieId: Number.isNaN(route.query.serieId)
          ? 0
          : Number.parseInt(route.query.serieId),
      };
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "products" */ "@/views/orders/Orders.vue"),
  },
  { path: "/unauthorized", name: "Unauthorized", component: Unauthorized },
  { path: "/*", name: "NotFound", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Navigation guard
router.beforeEach(async (to, from, next) => {
  const routeName = to.name.toLowerCase();
  if (["login", "unauthorized", "notfound"].includes(routeName)) {
    next();
  } else {
    let currentOdinUserRoles =
      store.state.authorization.currentOdinUser?.userRoles;
    if (!currentOdinUserRoles) {
      currentOdinUserRoles = await store.dispatch(
        "authorization/fetchCurrentOdinUser"
      );
      currentOdinUserRoles =
        store.state.authorization.currentOdinUser?.userRoles;
    }
    await store.dispatch("suppliers/getCurrentSupplier");
    if (!security.hasAccess(to, currentOdinUserRoles)) {
      return next({ name: "Unauthorized" });
    }
    return next();
  }
});

export default router;
