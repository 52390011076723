<template>
  <div class="home-page">
    <h1 class="pt-5 pb-3">Fixit Supplier Tools</h1>
    <section class="box-menu-section">
      <box-menu
        :items="supplierMenuFiltered"
        :row-items-count="boxMenuOptions.rowItemsCount"
      ></box-menu>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BoxMenu from "@/components/BoxMenu";

export default {
  components: { BoxMenu },
  name: "Home",
  
  data() {
    return {
      supplierMenu: [
        {
          title: "Fil-import",
          supplierLevelRange: {
            min: 0,
            max: 0
          },
          roles:['Supplier'],
          access: {
            case: '',
            roleNeeded: 'Basic'
          },
          target: {
            path: "/basic-import",
            external: false
          },
          icon: "mdi-upload",
        },
        {
          title: "Mine produkter",
          supplierLevelRange: {
            min: 0,
            max: 100
          },
          roles:['all'],
          access: {
            case: '',
            roleNeeded: 'Alle'
          },
          target: {
            path: "/brands",
            external: false
          },
          icon: "mdi-human-dolly",
        },
        {
          title: "Kampanjer",
          supplierLevelRange: {
            min: 1,
            max: 100
          },
          roles:['Supplier'],
          access: {
            case: '',
            roleNeeded: 'Nivå 1'
          },
          target: {
            path: "https://timebestilling.fixitonline.no/",
            external: true
          },
          icon: "mdi-sale",
        },
        {
          title: "Ordre",
          supplierLevelRange: {
            min: 2,
            max: 100
          },
          roles:['all'],
          access: {
            case: '',
            roleNeeded: 'Nivå 2'
          },
          target: {
            path: "https://produkter.fixitonline.no/p/multiordertool/supplierOfferList",
            external: true
          },
          icon: "mdi-cart-variant",
        },
        {
          title: "Agenter",
          supplierLevelRange: {
            min: 2,
            max: 100
          },
          roles:['Supplier'],
          access: {
            case: '',
            roleNeeded: 'Nivå 2'
          },
          target: {
            path: "https://produkter.fixitonline.no/OPP/#location=suppliersettings",
            external: true
          },
          icon: "mdi-human-greeting-variant",
        },
        {
          title: "Måleverktøy",
          supplierLevelRange: {
            min: 2,
            max: 100
          },
          roles:['Supplier'],
          access: {
            case: '',
            roleNeeded: 'Nivå 2'
          },
          target: {
            path: "https://produkter.fixitonline.no/OPP/#location=measuring",
            external: true
          },
          icon: "mdi-finance",
        },

        {
          title: "Eksklusivitet",
          supplierLevelRange: {
            min: 2,
            max: 100
          },
          roles:['Supplier'],
          access: {
            case: '',
            roleNeeded: 'Nivå 2'
          },
          target: {
            path: "https://produkter.fixitonline.no/OPP/#location=supplier-exclusivity",
            external: true
          },
          icon: "mdi-star-outline",
        },
        {
          title: "Leveransegebyr",
          supplierLevelRange: {
            min: 2,
            max: 100
          },
          roles:['Supplier'],
          access: {
            case: '',
            roleNeeded: 'Nivå 2'
          },
          target: {
            path: "https://produkter.fixitonline.no/OPP/#location=supplier-shippingsettings",
            external: true
          },
          icon: "mdi-truck",
        },
        {
          title: "Salongsperre",
          supplierLevelRange: {
            min: 2,
            max: 100
          },
          roles:['Supplier'],
          access: {
            case: '',
            roleNeeded: 'Nivå 2'
          },
          target: {
            path: "https://produkter.fixitonline.no/OPP/#location=supplier-salon-block",
            external: true
          },
          icon: "mdi-lock",
        },
        {
          title: "Feilmeldinger",
          supplierLevelRange: {
            min: 0,
            max: 100
          },
          roles:['all'],
          access: {
            case: '',
            roleNeeded: 'Alle'
          },
          target: {
            path: "https://produkter.fixitonline.no/OPP/#location=errorreport",
            external: true
          },
          icon: "mdi-bell-alert",
        },
        {
          title: "API dokumentasjon",
          supplierLevelRange: {
            min: 0,
            max: 100
          },
          roles:['all'],
          access: {
            case: '',
            roleNeeded: 'Alle'
          },
          target: {
            path: "/api-documentation",
            external: false
          },
          icon: "mdi-book-open-variant",
        },
      ],
      boxMenuOptions: {
        rowItemsCount: 5,
      },
    };
  },
  computed: {
    supplierMenuFiltered() {
      return this.formatList(this.supplierMenu, this.currentSupplierLevel).filter(this.removeUnavailableFilter).filter(this.filterByRole);
    },
    currentOdinUser() { return this.$store.state.authorization.currentOdinUser },
    currentSupplier() { return this.$store.state.suppliers.currentSupplier },
    currentSupplierLevel(){ return this.$store.state.suppliers.currentSupplierLevel },
    currentUserRole(){ return this.$store.state.authorization.userRole },
  },
  methods: {
    filterByRole: function(item){
      if(item.roles.includes('all')){
        return true
      } else if(item.roles.includes(this.currentUserRole)){
        return true
      } else { return false }
    },
    removeUnavailableFilter: function(item){
      const csl = this.currentSupplierLevel;
      return (csl <= item.supplierLevelRange.max);
    },
    formatList: function(l, csl){
      return l.map((it) => {
        if(it.supplierLevelRange.min > csl){
          it.access.case = 'noAccess' 
        } else {
          it.access.case = 'hasAccess'
        }
        return it 
        })
    }
  }
};

</script>
<style lang="scss" scoped>
.home-page {
  text-align: center;
}
.box-menu-section {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
