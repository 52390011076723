import $wait from '@/plugins/wait'

export default [
    {
        step: 1,
        route: 'BasicImport.CreateNew',
        title: 'New Import',
        subtitle: '',
        isLoading: () => $wait.is('loading basic-import-wizard create-new') || $wait.is('loading basic-import get-active-import'),
        component: () => import('./views/NewImport')
    },
    {
        step: 2,
        route: 'BasicImport.ColumnsMapping',
        title: 'Column Mapping',
        subtitle: '',
        isLoading: () => $wait.is('loading basic-import-wizard column-mapping') || $wait.is('loading basic-import get-active-import') || $wait.is('loading basic-import get-fixit-columns'),
        component: () => import('./views/ColumnMapping')
    },
    {
        step: 3,
        route: 'BasicImport.Preview',
        title: 'Preview',
        subtitle: '',
        isLoading: () => $wait.is('loading basic-import-wizard run-import'),
        component: () => import('./views/Preview')
    }
]
