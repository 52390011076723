export default class Brand {
  constructor(args) {
    this.id = args?.id;
    this.cloudinaryImageId = args?.cloudinaryImageId;
    this.dataProviderId = args?.dataProviderId;
    this.dataProviderBrandId = args?.dataProviderBrandId;
    this.imageUrl = args?.imageUrl;
    this.imageTransformation = args?.imageTransformation;
    this.infoText = args?.infoText;
    this.infoTitle = args?.infoTitle;
    this.name = args?.name;
    this.supplierId = args?.supplierId;
    this.supplierName = args?.supplierName;
    this.serieCount = args?.serieCount;
    this.productCount = args?.productCount;
  }
}
