import ColumnMapping from "./ColumnMapping";

export default class BasicImport {
    constructor(args) {
        this.id = args?.id
        this.supplierId = args?.supplierId
        this.createdTime = args?.createdTime
        this.createdByUserId = args?.createdByUserId
        this.status = args?.status
        this.importType = args?.importType
        this.includeHeaders = args?.includeHeaders
        this.excludeProductsMissingSalesPrice = args?.excludeProductsMissingSalesPrice
        this.excludeProductsMissingBarcodes = args?.excludeProductsMissingBarcodes
        this.excludeExistingProducts = args?.excludeExistingProducts
        this.isTestImport = args?.isTestImport
        this.parsedFileHeaders = (args?.parsedFileHeaders || [])
        this.columnMappings = (args?.columnMappings || []).map(x => new ColumnMapping(x))
    }
}
