import mutations from "@/store/mutations";
import ProductService from "@/services/products";
import keyBy from "lodash.keyby";
import orderBy from "lodash.orderby";

export default {
  namespaced: true,
  state: {
    products: {}, // store products in a dictionary with  product.id as key
    supplierProducts: [], // array of products for a supplier used in products overview
    fetchedProductsForSerie: {}, // keep track if which series all products have been fetched for. (key serie.id , value: boolean)
    fetchedProductsForSupplier: false,
  },
  getters: {
    getProducts(state) {
      const products = Object.values(state.products);
      return orderBy(products, "name");
    },
    getSupplierProducts(state) {
      return state.supplierProducts;
    },
    getProductsBySerieId: (state, getters) => (serieId) => {
      const hasFetchedProductsForSerie = state.fetchedProductsForSerie[serieId];
      return hasFetchedProductsForSerie
        ? getters.getProducts.filter(
            (product) => product.productSeriesId === serieId
          )
        : [];
    },
    getProductById: (state) => (id) => {
      return state.products[id];
    },
  },
  actions: {
    async fetchProductsBySupplierId({ state, commit }, supplierId) {
      if (state.fetchedProductsForSupplier) {
        return state.supplierProducts;
      }
      const result = await ProductService.getProductsBySupplierId(supplierId);
      const supplierProducts = result.map((p) => ({
        ...p,
        searchString:
          `${p.eans} ${p.supplementaryNumber} ${p.brandName} ${p.productSerieName} ${p.productName} ${p.categoryName}`.toLowerCase(),
      }));
      commit("setSupplierProducts", supplierProducts);
      commit("set", {
        key: "fetchedProductsForSupplier",
        value: true,
      });
      return supplierProducts;
    },
    async fetchProductsBySerieId({ commit }, serieId) {
      const products = await ProductService.getProductsBySerieId(serieId);
      commit("set", {
        key: "fetchedProductsForSerie",
        value: { [serieId]: true },
      });
      commit("addOrUpdate", products);
      return products;
    },
    async fetchProductById({ commit }, id) {
      const product = await ProductService.getProductById(id);
      commit("addOrUpdate", product);
      return product;
    },
    async createProduct({ commit }, product) {
      const savedProduct = await ProductService.createProduct(product);
      commit("addOrUpdate", savedProduct);
      commit("set", {
        key: "fetchedProductsForSupplier",
        value: false,
      });
      return savedProduct;
    },
    async updateProduct({ commit }, product) {
      const savedProduct = await ProductService.updateProduct(product);
      commit("addOrUpdate", savedProduct);
      commit("set", {
        key: "fetchedProductsForSupplier",
        value: false,
      });
      return savedProduct;
    },
    async fetchProductCounts({}, supplierId) {
      const counts = await ProductService.fetchProductCounts(supplierId);
      return counts;
    },
  },
  mutations: {
    ...mutations,
    addOrUpdate(state, products) {
      if (products) {
        state.products = {
          ...state.products,
          ...keyBy([].concat(products), "id"),
        };
      }
    },
    setSupplierProducts(state, supplierProducts) {
      state.supplierProducts = supplierProducts;
    },
  },
};
