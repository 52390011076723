import axios from "axios";
import ServiceException from "@/models/ServiceException";
import Serie from "@/models/Serie";

const baseURL = `${process.env.VUE_APP_API_URL}/api`;

export default {
  async getSerieById(id) {
    if (!id) return null;
    const response = await axios.get(`${baseURL}/series/${id}`);
    if (response?.data?.responseCode !== 200) {
      throw new ServiceException(response?.data);
    }
    return response.data.result;
  },
  async getSeriesByBrandId(brandId) {
    if (!brandId) return [];
    const response = await axios.get(`${baseURL}/brands/${brandId}/series`);
    if (response?.data?.responseCode !== 200) {
      throw new ServiceException(response?.data);
    }
    return response.data.result;
  },

  async updateSerie(serie) {
    if (!(serie instanceof Serie)) {
      throw new ServiceException({
        responseCode: 400,
        responseMessage: "Bad request",
      });
    }
    const response = await axios.put(`${baseURL}/series/${serie.id}`, serie);
    if (response?.data?.responseCode !== 200) {
      throw new ServiceException(response?.data);
    }
    return response.data.result;
  },

  async createSerie(serie) {
    if (!(serie instanceof Serie)) {
      throw new ServiceException({
        responseCode: 400,
        responseMessage: "Bad request",
      });
    }
    const response = await axios.post(`${baseURL}/series`, serie);
    if (response?.data?.responseCode !== 200) {
      throw new ServiceException(response?.data);
    }
    return response.data.result;
  },
};
