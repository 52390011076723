import { getObjectValueByPath } from '@fixit/utils';
import cloneDeep from 'lodash.clonedeep';

export const all = state => Object.values(state)

export const get = state => id => cloneDeep(state[id])

// export const has = state => (id, value) => Array.isArray(getObjectValueByPath(state, id)) && getObjectValueByPath(state, id).find(s => s.value.toLowerCase() === value.toLowerCase())

export default {
    all,
    get
}
