<template>
  <v-container fluid>
    <div
      v-if="showProductLoader"
      class="d-flex justify-center align-center mt-16"
    >
      <progress-bouncing></progress-bouncing>
    </div>

    <div v-else class="grid">
      <v-sheet class="product-filter-container" :style="sidebarStyle">
        <v-text-field
          clearable
          text
          hide-details
          prepend-inner-icon="mdi-magnify"
          placeholder="Søk"
          @input="updateSearch($event)"
        ></v-text-field>
        <v-treeview
          dense
          v-model="treeSelection"
          selection-type="leaf"
          :items="tree"
          selectable
          return-object
          disable-per-node
          open-all
        >
          <template #label="{ item }">
            <template v-if="item.filterType === 'seriesName'">
              {{ item.name }}
              <span
                class="text--secondary"
                v-if="item.serieNameInMultipleBrands"
              >
                ({{ item.brandName }})
              </span>
            </template>
            <template v-else>
              {{ item.name }}
            </template>
          </template>
        </v-treeview>
      </v-sheet>
      <main class="mb-8 pa-2">
        <div class="mb-8 pa-4">
          <h2>
            Viser {{ filteredProducts.length }} av totalt
            {{ products.length }} produkter.
          </h2>
        </div>

        <div class="d-flex fluid align-center mb-8">
          <v-container fluid>
            <v-data-iterator
              :items-per-page="-1"
              hide-default-footer
              :items="visibleProducts"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="item in props.items"
                    :key="item.id"
                    cols="12"
                    sm="9"
                    md="4"
                    lg="3"
                    xl="2"
                  >
                    <product-card
                      :product="item"
                      :tag="item.tag"
                      tagPosition="right"
                      :to="{ name: 'Product', params: { id: item.id } }"
                      :imageOptions="{ minHeight: 240 }"
                      priceDescription="Veil. innpris"
                      height="100%"
                    >
                    </product-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-container>
        </div>
      </main>
    </div>
  </v-container>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import ProgressBouncing from "@fixit/progress-bouncing";
import orderBy from "lodash/orderBy";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import filterStringMapToCountName from "./maps/filter";

let onScrollFn = null;
const union = (a, b) => {
  return new Set([...a, ...b]);
};

export default {
  name: "Products",
  components: {
    ProductCard,
    ProgressBouncing,
  },
  data() {
    return {
      loadingSerie: false,
      loadingProducts: false,
      errorSerie: false,
      errorProducts: false,
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      itemsPerPageArray: [4, 8, 12],
      treeSelection: [],
      tree: [],
      treeBrands: [],
      treeSeries: [],
      treeSeriesMap: {},
      treeBrandSeries: {},
      counts: [],
      filterStringMapToCountName,
      translateY: 0,
    };
  },
  computed: {
    visibleProducts() {
      const sortedProducts = orderBy(
        this.filteredProducts,
        [(p) => p.productName.trim().toLowerCase()],
        ["asc"]
      );
      return sortedProducts.slice(0, 120).map(toCardProduct);
    },

    filteredProducts() {
      const tSel = this.treeSelection;
      let search = (this.search || "").toLowerCase().trim();
      if (tSel.length === 0 && !search) return this.products;
      if (search.length > 0 && search.length < 3) return [];
      let searchWords = search.split(" ");
      const matchedWordsPercentage = function (words, ss) {
        if (words.length === 0) {
          return 1;
        } else {
          let matchedWords = words.filter((w) => ss.includes(w));
          return matchedWords.length / words.length;
        }
      };

      const tagFilter = tSel
        .map((obj) => {
          if (obj.filterType === "tag") {
            return obj.filterValue;
          }
        })
        .filter((obj) => {
          return obj !== undefined;
        });
      const activeStatusFilter = tSel
        .map((obj) => {
          if (obj.filterType === "activeStatus") {
            return obj.filterValue;
          }
        })
        .filter((obj) => {
          return obj !== undefined;
        });
      const brandNameFilter = tSel
        .map((obj) => {
          if (obj.filterType === "brandName") {
            return obj.filterValue;
          }
        })
        .filter((obj) => {
          return obj !== undefined;
        });
      const serieNameFilter = tSel
        .map((obj) => {
          if (obj.filterType === "seriesName") {
            return obj.filterValue;
          }
        })
        .filter((obj) => {
          return obj !== undefined;
        });

      const onlyProductsMissingImage = tSel.some(
        (obj) => obj.filterType === "missingImage"
      );

      const onlyProductsMissingDescription = tSel.some(
        (obj) => obj.filterType === "missingDescription"
      );

      return this.products.filter(function (product) {
        let matchP = matchedWordsPercentage(searchWords, product.searchString);
        if (tSel.length === 0) return matchP >= 0.75;
        return (
          (matchP >= 0.75 || product.searchString.includes(search)) &&
          (tagFilter.length === 0 || tagFilter.includes(product.articleType)) &&
          (activeStatusFilter.length === 0 ||
            activeStatusFilter.includes(product.activeStatus)) &&
          (brandNameFilter.length === 0 ||
            brandNameFilter.includes(product.brandId)) &&
          (serieNameFilter.length === 0 ||
            serieNameFilter.includes(product.productSerieId)) &&
          (!onlyProductsMissingImage ||
            (onlyProductsMissingImage && !product.hasImage)) &&
          (!onlyProductsMissingDescription ||
            (onlyProductsMissingDescription && !product.hasDescription))
        );
      });
    },
    products() {
      return this.$store.getters["products/getSupplierProducts"].map((p) => ({
        ...p,
        articleType: p.articleType !== "F" ? "S" : "F",
      }));
    },
    showProductLoader() {
      return this.products.length === 0 && this.loadingProducts;
    },
    showErrorMessageProducts() {
      return !this.loadingProducts && this.errorProducts;
    },
    treeSelectedBrands() {
      return this.treeSelection.filter((f) => f.filterType === "brandName");
    },
    // Create string for looking up pre-fetched counts. E.g. "FS012" => Forbruksvarer, Salgsvarer, Aktive, Kommende, Utgåtte
    filterString() {
      const tagFilter =
        this.treeSelection
          .filter((obj) => obj.filterType === "tag")
          .map((obj) => obj.filterValue)
          .sort()
          .join("") || "FS";
      const activeStatusFilter =
        this.treeSelection
          .filter((obj) => obj.filterType === "activeStatus")
          .map((obj) => obj.filterValue)
          .sort()
          .join("") || "012";
      return tagFilter + activeStatusFilter;
    },
    countName() {
      return this.filterStringMapToCountName[this.filterString] || "products";
    },

    brandSerieCounts() {
      const counts = this.counts.reduce(
        (map, count) => {
          if (!map.brands.has(count.brandId)) {
            map.brands.set(count.brandId, { ...count }); // Spread to avoid mutating original count object
          } else {
            // Sum counts
            const brandCount = map.brands.get(count.brandId);
            for (const [key, value] of Object.entries(count)) {
              if (
                key !== "brandName" &&
                key !== "serieName" &&
                key !== "brandId" &&
                key !== "serieId"
              ) {
                brandCount[key] += value;
              }
            }
          }
          map.series.set(count.serieId, { ...count });
          return map;
        },
        { series: new Map(), brands: new Map() }
      );
      return counts;
    },
    sidebarStyle() {
      return {
        transform: `translateY(${this.translateY}px)`,
      };
    },
  },
  watch: {
    filterString() {
      this.updateSelectableBrands();
    },
    treeSelectedBrands() {
      this.updateSelectableSeries();
    },
  },
  methods: {
    onScroll() {
      const element = document.documentElement;
      const menuNav = document.querySelector("#fixit-menu-navigation header");

      if (!menuNav) return;
      // Top
      if (document.documentElement.scrollTop <= 0) {
        this.translateY = 0;
        return;
      }
      // Bottom
      const isBottom =
        Math.abs(
          element.scrollHeight - element.clientHeight - element.scrollTop
        ) < 40; // some px bottom tolerance
      if (isBottom) {
        const toolbarHeight = 70;
        const extendedHeight =
          document.querySelector(
            "#fixit-menu-navigation header .v-toolbar__extension"
          ) != null
            ? 48
            : 0;
        this.translateY = toolbarHeight + extendedHeight;
        return;
      }

      // Middle
      const { y, height } = menuNav.getBoundingClientRect();
      this.translateY = Math.round(y + height);
    },
    updateSelectableBrands() {
      const selectableBrands = this.treeBrands.filter(
        (b) => this.getCount("brands", b.filterValue) > 0
      );
      // Update tree with only brands with product counts
      this.tree.find((n) => n.id === 7).children = selectableBrands;
    },
    updateSelectableSeries() {
      const selectedBrands = this.treeSelectedBrands;
      const selectableBrands = this.treeBrands.filter(
        (b) => this.getCount("brands", b.filterValue) > 0
      );
      if (
        selectedBrands.length === 0 ||
        selectedBrands.length === selectableBrands.length
      ) {
        this.tree.find((n) => n.id === 8).children = this.treeSeries.filter(
          (s) => this.getCount("series", s.filterValue) > 0
        );
        return;
      }

      // Update tree with only series from selected brands
      const selectableSeries = selectedBrands.reduce(
        (series, brand) =>
          union(series, this.treeBrandSeries.get(brand.filterValue)),
        new Set()
      );

      const series = [...selectableSeries]
        .filter((s) => this.getCount("series", s) > 0)
        .map((s) => this.treeSeriesMap.get(s))
        .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
      // Update tree with only series from selected brands and remove selected series not in selected brands
      this.tree.find((n) => n.id === 8).children = series;
    },
    getCount(type, id) {
      const counts = this.brandSerieCounts[type].get(id);
      if (!counts) return 0;
      return counts[this.countName] || 0;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async fetchProducts() {
      try {
        this.loadingProducts = true;
        const [_products, counts] = await Promise.all([
          this.$store.dispatch(
            "products/fetchProductsBySupplierId",
            this.$store.state.authorization.currentOdinUser.supplierId
          ),
          this.$store.dispatch(
            "products/fetchProductCounts",
            this.$store.state.authorization.currentOdinUser.supplierId
          ),
        ]);
        this.counts = counts;
      } catch (err) {
        console.error(err);
        this.errorProducts = err;
      } finally {
        this.loadingProducts = false;
      }
    },
    async populateTree() {
      // Declare all filter options
      var availabilityActive = {
        id: 1,
        name: "Aktive",
        filterValue: 1,
        filterType: "activeStatus",
      };
      var availabilityExpired = {
        id: 2,
        name: "Utgåtte",
        filterValue: 0,
        filterType: "activeStatus",
      };
      var availabilityComing = {
        id: 3,
        name: "Kommende",
        filterValue: 2,
        filterType: "activeStatus",
      };
      var articleTypeSalesItem = {
        id: 4,
        name: "Salgsvarer",
        filterValue: "S",
        filterType: "tag",
      };
      var articleTypeConsumableItem = {
        id: 5,
        name: "Forbruksvarer",
        filterValue: "F",
        filterType: "tag",
      };
      var brandParent = { name: "Merker", id: 7, children: [] };
      var seriesParent = { name: "Serier", id: 8, children: [] };

      const hasImageItem = {
        id: 9,
        name: "Mangler bilde",
        filterType: "missingImage",
      };
      const hasDescriptionItem = {
        id: 10,
        name: "Mangler beskrivelse",
        filterType: "missingDescription",
      };

      //ALL filter items
      this.tree.push(
        availabilityExpired,
        availabilityActive,
        availabilityComing,
        articleTypeSalesItem,
        articleTypeConsumableItem,
        hasImageItem,
        hasDescriptionItem,
        brandParent,
        seriesParent
      );
      //Preselect filter items
      this.treeSelection.push(
        availabilityActive,
        availabilityComing,
        articleTypeSalesItem,
        articleTypeConsumableItem
      );
    },
    populateTreeChildren() {
      // Get unique brands and series from products
      const brandSerieMap = this.products.reduce(
        (maps, product) => {
          maps.brandMap.set(product.brandId, {
            brandId: product.brandId,
            brandName: product.brandName,
          });
          maps.serieMap.set(product.productSerieId, {
            productSerieId: product.productSerieId,
            productSerieName: product.productSerieName,
            brandName: product.brandName,
          });
          return maps;
        },
        {
          brandMap: new Map(),
          serieMap: new Map(),
        }
      );
      const brandList = [...brandSerieMap.brandMap.values()].sort((a, b) =>
        a.brandName.trim().localeCompare(b.brandName.trim())
      );

      const seriesList = [...brandSerieMap.serieMap.values()].sort((a, b) =>
        a.productSerieName.trim().localeCompare(b.productSerieName.trim())
      );

      const countSameSerieName = seriesList.reduce((map, serie) => {
        if (!map.has(serie.productSerieName)) {
          map.set(serie.productSerieName, 0);
        }
        map.set(serie.productSerieName, map.get(serie.productSerieName) + 1);
        return map;
      }, new Map());

      // Populating brand and series filter objects
      let iter = 100;
      this.treeBrands = brandList.map((b) => {
        iter++;
        return {
          id: iter,
          name: b.brandName,
          filterValue: b.brandId,
          filterType: "brandName",
        };
      });
      this.tree.find((n) => n.id === 7).children = this.treeBrands;

      this.treeSeries = seriesList.map((s) => {
        iter++;
        return {
          id: iter,
          name: s.productSerieName,
          filterValue: s.productSerieId,
          filterType: "seriesName",
          serieNameInMultipleBrands:
            countSameSerieName.get(s.productSerieName) > 1,
          brandName: s.brandName,
        };
      });
      this.tree.find((n) => n.id === 8).children = this.treeSeries;

      // For easier lookup on serieId
      this.treeSeriesMap = this.treeSeries.reduce((map, serie) => {
        map.set(serie.filterValue, serie);
        return map;
      }, new Map());

      // Associate brands with series
      this.treeBrandSeries = this.products.reduce((map, product) => {
        if (!map.has(product.brandId)) {
          map.set(product.brandId, new Set());
        }
        map.get(product.brandId).add(product.productSerieId);
        return map;
      }, new Map());
    },
  },
  async created() {
    onScrollFn = throttle(this.onScroll, 50);
    window.addEventListener("scroll", onScrollFn);
    this.updateSearch = debounce((textInput) => {
      this.search = textInput;
    }, 250);
    await this.fetchProducts();

    this.populateTree();
    this.populateTreeChildren();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", onScrollFn);
  },
};

const toCardProduct = (p) => ({
  id: p.id,
  tag: getProductTag(p),
  productName: p.productName,
  brandName: p.brandName,
  serieName: p.productSerieName,
  price: p.inPriceExVat,
  imageUrl: p.imageUrl,
  activeStatus: p.activeStatus,
  orderAvailableFrom: p.orderAvailableFrom,
  orderAvailableTo: p.orderAvailableTo,
});

const getProductTag = (p) => (p.articleType !== "F" ? "S" : "F");
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-areas: "sidebar main";
}

main {
  grid-area: main;
}

.product-filter-container {
  grid-area: sidebar;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  align-self: start;
  transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  .v-treeview {
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
