<template>
  <div>
    <v-dialog :value="$route.name.includes('BasicImport.')" fullscreen hide-overlay transition="none">
      <v-card v-if="currentStep">
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="$router.back()">
            <v-icon size="36">mdi-chevron-left</v-icon>
          </v-btn>
          <div class="title">Back</div>
          <!--          <v-toolbar-title>Basic import wizard</v-toolbar-title>-->
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="currentStep.step > 1" text dark @click="discard" :disabled="isExecuting" :loading="$wait.is('executing basic-import delete-import')">Discard</v-btn>
            <v-btn text dark @click="next" :loading="isExecuting">
              {{ currentStep.title === 'Summary' ? 'Close' : currentStep.title === "Preview" ? 'Run Import' : 'Next' }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <section class="mx-auto mt-7" :style="{'max-width': currentStep.step === 3 ? '90%' : '700px'}">
          <div v-for="step in steps" :key="step.step">
            <component :is="step.component" :key="step.step"
                       :is-loading="step.isLoading()"
                       v-if="step.route === $route.name"
                       ref="wizard-component"
            />
          </div>
        </section>
        <!--        <section class="step2">-->
        <!--          Please verify the import data, you may not start a new import before importdata is either comitted or-->
        <!--          disgarded.-->
        <!--        </section>-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import steps from './wizard-steps'
import {waitFor} from "vue-wait/src/helpers";
import wizardMixin from './views/wizard.mixin'

export default {
  name: 'BasicImportWizard',
  mixins: [wizardMixin],
  data() {
    return {
      steps,
      importFile: null,
      includeFileHeader: false,
      dontUpdateProducts: false,
      excludeNoBarcodeProducts: false,
      excludeNoSalesPriceProducts: false
    }
  },
  methods: {
    async next() {
      waitFor('executing basic-import action', async () => {
        let proceed = true
        let submit = this.$refs['wizard-component'][0].formSubmit
        if (submit)
          proceed = await submit()
        if (proceed) {
          let nextRoute
          switch (this.$route.name) {
            case 'BasicImport.CreateNew':
              nextRoute = 'BasicImport.ColumnsMapping'
              break
            case 'BasicImport.ColumnsMapping':
              nextRoute = 'BasicImport.Preview'
              break
            case 'BasicImport.Preview':
              nextRoute = 'BasicImport'
              break
            default:
              nextRoute = 'BasicImport'
          }
          await this.$router.push({name: nextRoute})
        }
      }).apply(this)
    },
    async discard() {
      waitFor('executing basic-import delete-import', async () => {
        await this.$store.dispatch('basic-import/deleteImport', {importId: this.basicImport.id})
        await this.$router.push({name: 'BasicImport'})
      }).apply(this)
    }
  },
  computed: {
    isExecuting() {
      return this.$wait.is('executing basic-import action')
    },
    stepIsLoading() {
      return this.currentStep.isLoading()
    },
    currentStep() {
      return this.steps.find(x => x.route === this.$route.name)
    }
  },
  watch: {
    '$route.name': {
      handler(val) {
        if (val === 'BasicImport')
          this.$store.commit('basic-import/set', {key: 'basicImport', value: null})
      }, immediate: true
    }
  }
}
</script>
<style lang="scss" scoped></style>
