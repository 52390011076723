<template>
  <div class="grid-menu-container" :style="cssProps">
    <div class="grid-menu-item" v-for="(item) in items" :key="item.title">
      <v-card :color="availableItemColor" :class="{unavailable: (item.access.case !== 'hasAccess')}"
              elevation="2"
              outlined
              tile
      >
        <a v-if="item.target.external" :href="item.target.path">
          <h4>{{ item.title }}</h4>
          <v-icon>{{ item.icon }}</v-icon>
        </a>
        <router-link v-else :to="item.target.path">
          <h4>{{ item.title }}</h4>
          <v-icon>{{ item.icon }}</v-icon>
        </router-link>
      </v-card>
      <div v-if="item.access.case =='noAccess'" class="denial-explanation"><v-icon>mdi-lock</v-icon>{{ item.access.roleNeeded }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BoxMenu',
  props: {
    items: {
      type: Array,
      required: true
    },
    rowItemsCount: {
      type: Number,
      default: 6,
      required: false
    },
    menuItemMinHeight: {
      type: String,
      default: '140px',
      required: false
    },
    paddingWidth: {
      type: String,
      default: '10px',
      required: false
    },
    availableItemColor: {
      type: String,
      default: 'primary',
      required: false
    },
    unAvailableItemOpacity: {
      type: Number,
      default: 0.45,
      required: false
    },
    fontSize: {
      type: String,
      default: '22px',
      required: false
    },
    fontColor: {
      type: String,
      default: 'white',
      required: false
    },
    iconColor: {
      type: String,
      default: 'white',
      required: false
    },
    iconFontSize: {
      type: String,
      default: '46px',
      required: false
    }
  },

  methods: {
    setStyleForItem(index) {
      return {
        'pl-0': (index % this.rowItemsCount == 0),
        'pr-0': (index % this.rowItemsCount == (this.rowItemsCount - 1))
      }
    }
  },
  computed: {
    cssProps() {
      return {
        '--menuItemWidth': 'calc(100% / ' + this.rowItemsCount + ' )',
        '--menuItemMinHeight': this.menuItemMinHeight,
        '--paddingWidth': this.paddingWidth,
        '--availableItemColor': this.availableItemColor,
        '--unAvailableItemOpacity': this.unAvailableItemOpacity,
        '--fontSize': this.fontSize,
        '--fontColor': this.fontColor,
        '--iconFontSize': this.iconFontSize,
        '--iconColor': this.iconColor,
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.grid-menu-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > .grid-menu-item {
    width: var(--menuItemWidth);
    padding: var(--paddingWidth);
    position: relative;
    >.denial-explanation{
      padding: 2px 8px 2px 4px;
      position: absolute;
      bottom: var(--paddingWidth);
      right: var(--paddingWidth);
      // width: calc(100% - (var(--paddingWidth)*2));
      background-color: rgba(245, 245, 245, 0.327);
      color: rgb(104, 104, 104);
      line-height: 28px;
      >i{
        color: rgb(104, 104, 104);
      }
    }
    > div:not(.denial-explanation) {
      min-height: var(--menuItemMinHeight);
      font-size: var(--fontSize);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 20px 0;

      * {
        text-decoration: none;
        color: var(--fontColor);
      }

      &.unavailable {
        opacity: var(--unAvailableItemOpacity);
        filter: grayscale(100%);

        * {
          pointer-events: none;
        }
      }

      &:not(.unavailable) {
        cursor: pointer;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }
    ;

      i {
        width: 100%;
        font-size: var(--iconFontSize);
        color: var(--iconColor);
      }
    }
  }
}
</style>
