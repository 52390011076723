<template>
  <nav>
    <v-navigation-drawer permanent app sticky>
      <div>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 text-left">
            API documentation
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.Id"
          link
          class="drawerMenuItem"
          :data-relatedSectionId="item.urlName"
          v-bind:class="{'subItem': item.properties.find(p => p.propertyTypeAlias=== 'menuLayer').content === '1'}"
          @click="singlePageIdNavigate(item.urlName)"
        >
          <v-list-item-content class="text-left">
            <v-list-item-title v-text="item.properties.find(p => p.propertyTypeAlias=== 'sectionName').content"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      </div>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import goTo from "vuetify/lib/services/goto"
export default {
  name: "DocumentationDrawerMenu",
  data(){
    return {
      headerSmallOffset: -58,
      headerBigOffset: 12,
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: null
    }
  },
  methods: {
    singlePageIdNavigate(target){ // TODO: tweak focus after final fe
      let el = document.getElementById(target)
      if(el){
        var headerScrolled = (document.querySelector('.v-toolbar__content').getBoundingClientRect().top === -70)
        let elPos = el.getBoundingClientRect().top // Used to determine position of target element relative to current scroll
        
        if(headerScrolled){ // Header minimized
          if(elPos >= 59 && elPos <= 61){
            return false
          } 
          else if(elPos > 68){ // Moving down to close or far
            goTo(el, {'offset': this.headerSmallOffset})
          } 
          else if(elPos <= 10){ // Moving up to far element
            goTo(el, {'offset': (this.headerBigOffset)})
          } 
          else if(elPos > 10 && this.elPos < 58){ // Moving up to close element
            goTo(el, {'offset': this.headerSmallOffset})
          } 
        }
        else { // Header maximized
          if(elPos >= 129 && elPos <= 131){
            return false
          } 
          else if(elPos < 140){ // Moving up
            goTo(el, {'offset': this.headerBigOffset})
          }
          else if(elPos > 140 && elPos < 210){ // moving down to close element 
            goTo(el, {'offset': this.headerBigOffset})
          }
          else if(elPos >= 210){ // moving down to far element 
            goTo(el, {'offset': this.headerSmallOffset})
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
nav{
.drawerMenuItem {
  min-height: 30px;
  &.activeMenuItem >div{
    background-color: rgb(71 36 73 / 6%);
    *{
      color: purple;
    }
  }
  >div{
    padding: 3px 0 3px 0px;
  }
  &.subItem{
    min-height: 10px;
    >div{
      padding: 3px 0 3px 16px;
    }
  }
}
}
</style>