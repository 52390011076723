import axios from "axios";
import ServiceException from "@/models/ServiceException";


export default {
  async getContent() {
    const response = await axios.get(process.env.VUE_APP_FIXIT_CORE_API_URL + '/Api/Home/GetCmsDataByUrlPath', {params: {url: window.location.hostname === 'localhost' ? 'http://supplier.test.odindata.no/api-documentation' : window.location.href}})
    if (response?.status !== 200) {
      throw new ServiceException(response?.data);
    }
    return response;
  },
};
