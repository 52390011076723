import { isObject, isFunction } from '@fixit/utils'

export function clear(state, stateNames) {
    [].concat(stateNames).forEach(stateName => {
        if (stateName in state) {
            state[stateName] = null;
        }
    });
}

/**
 * Setting new state values. Accepting either an Object or an Array of Objects.
 * @param {Object} state - Current state
 * @param {Object|Object[]} payload - Payload to update the state with.
 */
export function set(state, payload) {
    [].concat(payload).map(p => Object(p)).forEach(p => {
        if (p.key in state) {
            // Array replacement
            if (Array.isArray(payload.value)) {
                state[payload.key] = payload.value.slice();
            }

            // Object assigning
            else if (isObject(payload.value)) {
                state[payload.key] = Object.assign({}, state[payload.key], payload.value);
            }

            // Replace state
            else {
                state[payload.key] = payload.value;
            }
        }
    });
}

export const REMOVE = (state, { key, find = () => {} }) => {
    if (key in state && Array.isArray(state[key]) && isFunction(find)) {
        const index = state[key].indexOf(state[key].find(find));
        if (~index) state[key].splice(index, 1);
    }
}

/**
 * Update value for an array element. Can also be used to update a specific property for the hit item in array.
 * @param {*} state - Vuex state 
 * @param {Object} payload - The mutation payload
 * @param {String} payload.key - The key of the state to update
 * @param {Function} payload.find - The testing function to find the array element to update.
 * @param {*} payload.value - The value to update with.
 * @param {String} payload.property - The property of the array element found, if you want to update the value of a specific property in the array element.
 */
export const UPDATE = (state, { key, find = () => {}, value, property }) => {
    if (key in state && Array.isArray(state[key]) && isFunction(find)) {
        let item = state[key].find(find);
        if (item) {
            if (Object(item).hasOwnProperty(property)) item[property] = value;
            else item = value;
        }
    }
}

export default {
    clear,
    set
}
